import {
    UPDATE_LOADING_STATUS, GET_REPORT_CASES, GET_REPORT_CASES_FAILURE, GET_REPORT_CASES_SUCCESS,
    SET_ACTIVE_STATUS, SET_REPORT_CASES_COUNT, UPDATE_ITEMS_PER_PAGE, UPDATE_PAGE_NUMBER,
    UPDATE_REPORT_SORT_ITEMS,
    SET_FILTERS_REFERENCE_DATA,
    SET_FILTERS_DATA,
    SAVE_ACKNOWLEDGEMENT_LOADING,
    UPDATE_REPORT_CASES,
    UPDATE_REPORT_CASES_SUCCESS,
    UPDATE_REPORT_CASES_FAILURE,
    RESET_PAGINATION_OPTIONS,
    RESET_REPORTS_CASE_COUNT,
    GET_CASE_AMENDMENT_SUCCESS,
    GET_CASE_AMENDMENT_FAILURE,
    SET_ALREADY_ACKNOWLEDGE_EMR,
    PENDING_VENDOR_CONF_SUCCESS,
    UPDATE_WORK_QUEUE_LIST_DATA,
    ADD_WORK_QUEUE_INDEX,
    SET_IS_NAVIGATE_WORK_QUEUE,
    SET_RETURN_TO_WORK_QUEUE,
    SET_ACTIVE_WORK_QUEUE
} from "../constants/ActionTypes";

const initialState = {
    cases: [],
    totalCases: '',
    loading: false,
    pageNumber: 0,
    itemsPerPage: 100,
    activeStatus: '',
    sortBy: [],
    config: [],
    setFilterData: [],
    statusCount: {
        RepConfirmationDue: '-',
        NoInsuranceAuthorizationProvided: '-',
        IncompleteInsuranceAuthorization: '-',
        PreSurgicalTestingatHospital: '-',
        PreAdmissionTestingAtHospital2: '_',
        NoConfirmationNumber: '-',
        CaseAmendmentTracker: '-',
        PendingConfirmationNumber: '-',
        InpatientAdmitType: '-',
        VendorConfirmationDue: '-',
    },   
    saveAcknowledgementLoading: false,
    alreadyAcknowledged: false,
    workQueueList: [],
    activeWorkQueue: {
        selectedWorkQueueIndex: null,
        activeStatus: {},
        isNavigateWorkQueue: false,
        isReturnToWorkQueue: false,
        caseId: null,
        caseIndex: null,
        pageNumber: 0,
        itemPerPage: 100,
    }
};


const ACTION_HANDLERS = {

    [GET_REPORT_CASES]: (state, action) => ({
        ...state,
        error: null,
        loading: action.loading
    }),
    [GET_REPORT_CASES_SUCCESS]: (state, action) => {
        return {
            ...state,
            cases: action.payload.Items,
            totalCases: action.payload.TotalRecords,
            loading: action.loading,
            saveAcknowledgementLoading: false,
            sortBy: action.payload.SortBy,
            config: action.payload.Config
        };
    },
    [GET_REPORT_CASES_FAILURE]: (state, action) => ({
        ...state,
        cases: [],
        error: action.payload,
    }),
    [UPDATE_REPORT_CASES]: (state, action) => ({
        ...state,
        error: null,
        loading: action.loading
    }),
    [UPDATE_REPORT_CASES_SUCCESS]: (state, action) => {
        return {
            ...state,
            cases: action.payload,
            loading: action.loading,
            saveAcknowledgementLoading: false,
        };
    },
    [UPDATE_REPORT_CASES_FAILURE]: (state, action) => ({
        ...state,
        cases: [],
        error: action.payload,
    }),
    [SET_ACTIVE_STATUS]: (state, action) => ({
        ...state,
        activeStatus: action.payload,
    }),

    [SET_REPORT_CASES_COUNT]: (state, action) => ({
        ...state,
        statusCount: action.payload,
    }),

    [UPDATE_PAGE_NUMBER]: (state, action) => ({
        ...state,
        pageNumber: action.payload,
    }),
    [UPDATE_ITEMS_PER_PAGE]: (state, action) => ({
        ...state,
        itemsPerPage: action.payload,
    }),
    [UPDATE_LOADING_STATUS]: (state, action) => ({
        ...state,
        loading: action.loading
    }),
    [SAVE_ACKNOWLEDGEMENT_LOADING]: (state, action) => ({
        ...state,
        saveAcknowledgementLoading: action.payload
    }),
    [UPDATE_REPORT_SORT_ITEMS]: (state, action) => ({
        ...state,
        sortBy: action.payload
    }),
    [SET_FILTERS_REFERENCE_DATA]: (state, action) => ({
        ...state,
        filterReferenceData: action.payload,
    }),
    [SET_FILTERS_DATA]: (state, action) => ({
        ...state,
        setFilterData: [...action.payload],
    }),
    [RESET_PAGINATION_OPTIONS]: (state, action) => ({
        ...state,
        pageNumber: 0,
        itemsPerPage: 100
    }),
    [RESET_REPORTS_CASE_COUNT]: (state, action) => ({
        ...state,
        statusCount: {
            RepConfirmationDue: '-',
            NoInsuranceAuthorizationProvided: '-',
            IncompleteInsuranceAuthorization: '-',
            PreSurgicalTestingatHospital: '-',
            PreAdmissionTestingAtHospital2: '_',
            NoConfirmationNumber: '-',
            CaseAmendmentTracker: '-',
            PendingConfirmationNumber: '-',
            InpatientAdmitType: '-',
        }
    }),
    [GET_CASE_AMENDMENT_SUCCESS]: (state, action) => ({
        ...state,
        error: null,
        cases: [...action.payload],
    }),
    [GET_CASE_AMENDMENT_FAILURE]: (state, action) => ({
        ...state,
        error: action.payload
    }),
    [SET_ALREADY_ACKNOWLEDGE_EMR]: (state, action) => ({
        ...state,
        alreadyAcknowledged: action.payload
    }),
    [PENDING_VENDOR_CONF_SUCCESS]: (state, action) => ({
        ...state,
        cases: [...action.payload],
    }),
    [UPDATE_WORK_QUEUE_LIST_DATA]: (state, action) => ({
        ...state,
        workQueueList: [...action.payload],
    }),
    [ADD_WORK_QUEUE_INDEX]: (state, action) => ({
        ...state,
        activeWorkQueue: {
            ...state.activeWorkQueue,
            selectedWorkQueueIndex: action.payload.workQueueIndex,
            activeStatus: action.payload.workQueueActiveStatus,
            caseId: null,
            caseIndex: null
        },
    }),
    [SET_IS_NAVIGATE_WORK_QUEUE]: (state, action) => ({
        ...state,
        activeWorkQueue: action.payload,
    }),
    [SET_RETURN_TO_WORK_QUEUE]: (state, action) => ({
        ...state,
        activeWorkQueue: {
            ...state.activeWorkQueue,
            isReturnToWorkQueue: action.payload
        },
    }),
    [SET_ACTIVE_WORK_QUEUE]: (state, action) => ({
        ...state,
        activeWorkQueue: action.payload,
    }),
};

export default function reports(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;

}



// Private Selectors
export const getReportsCases = state => state.reports.cases;
export const getTotalCases = state => state.reports.totalCases;
export const getActiveStatus = state => state.reports.activeStatus;
export const getLoadingStatus = state => state.reports.loading;
export const getSaveAcknowledgementLoadingStatus = state => state.reports.saveAcknowledgementLoading;
export const getStatusCount = state => state.reports;
export const getPageNumber = state => state.reports.pageNumber;
export const getItemsPerPage = state => state.reports.itemsPerPage;
export const getSortData = state => state.reports.sortBy;
export const getTableHeaderConfig = state => state.reports.config;
export const getFilterReferenceData = state => state.reports.filterReferenceData;
export const getFilterFiledValue = state => state.reports.setFilterData;
export const getAlreadyAcknowledged = state => state.reports.alreadyAcknowledged;
export const getWorkQueueListInfo = state => state.reports.workQueueList;
export const getActiveWorkQueueInfo = state => state.reports.activeWorkQueue;

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Sidebar from './Sidebar';
import ContentPage from './ContentPage';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getDashboardCases, getDashboardFilters } from '../../actions/DashboardAction';
import moment from 'moment';
import DashboardSearch from "../dashboard/DashboardSearch";
import {
    DASHBOARD_DEFAULT_PAGE_NUMBER,
    DASHBOARD_DEFAULT_ITEM_PER_PAGE,
    DASHBOARD_DATE_FORMAT
} from '../../constants/GlobalConstants';


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(10),
        background: '#fff',
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(11)})`,
    }
}))

//To get dashboard from and to date value
export const getDashboardFilterDateInfo = () => {
    const dashboardStartDate = sessionStorage.getItem("dashboardStartDate")
    const dashboardEndDate = sessionStorage.getItem("dashboardEndDate")

    var startDate = moment(new Date()).format(DASHBOARD_DATE_FORMAT);
    var endDate = moment(new Date()).format(DASHBOARD_DATE_FORMAT);

    if(dashboardStartDate) {
        if(dashboardStartDate == "null" || dashboardStartDate == null && dashboardEndDate){
          startDate = moment(dashboardEndDate).subtract(1, 'year').format(DASHBOARD_DATE_FORMAT)
        } else {
          startDate = moment(dashboardStartDate).format(DASHBOARD_DATE_FORMAT)
        }
      }

    if (dashboardEndDate) {
        if (dashboardEndDate == null || dashboardEndDate == "null" && dashboardStartDate) {
            endDate = moment(dashboardStartDate).add(1, 'year').format(DASHBOARD_DATE_FORMAT)
        } else {
            endDate = moment(dashboardEndDate).format(DASHBOARD_DATE_FORMAT)
        }
    }

    return { startDate, endDate }
}


function CaseView(props) {
    const { startDate, endDate } = getDashboardFilterDateInfo()

    useEffect(() => {
        if (!props.dashboardSearchBl && !props.dashboardFilters) {
            props.getDashboardCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, startDate, endDate);
        }
        sessionStorage.removeItem("activeWorkQueueInfo")
    }, [props.dashboardSearchBl]);

    const classes = useStyles();

    if (props.dashboardSearchBl) {
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        <DashboardSearch />
                    </Grid>
                </Grid>
            </div>
        )
    }
    else {
        return (
            <div className={classes.root}>
                <Grid container>
                    {/* <Grid item md={3} lg={2.5}>
                        <Sidebar
                            getDashboardCases={(ItemPerPage, PageNumber, startDate, endDate) => props.getDashboardCases(ItemPerPage, PageNumber, startDate, endDate)}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} md={9} lg={9.5}> */}
                    <Grid item xs={12}>
                        <ContentPage />
                    </Grid>
                </Grid>
            </div>
        )
    }

}

ContentPage.prototype = {
    getDashboardCases: PropTypes.func.isRequired,
    casesData: PropTypes.object.isRequired,
    dashboardFilters: PropTypes.object.isRequired,
    getDashboardFilters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        casesData: state.dashboard.casesData,
        dashboardFilters: state.dashboard.dashboardFiltersData,
        dashboardSearchBl: state.dashboard.isDashboardSearch,
    }
}
export default connect(mapStateToProps, { getDashboardCases, getDashboardFilters })(CaseView);

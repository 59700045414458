// Function to clear specific sessionStorage items for a tenant

const tenantKeys = ['dashboardEndDate', 'dashboardStartDate']; // Add more keys if needed

export const clearTenantSessionStorage = (keys =tenantKeys) => {
    if (!Array.isArray(keys)) {
      throw new Error('The argument must be an array of keys');
    }
  
    keys.forEach((key) => {
      sessionStorage.removeItem(key);
    });
  };
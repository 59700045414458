import React from 'react';
import { Divider, Typography, Grid, colors, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';
import ScreenLockPortraitIcon from '@mui/icons-material/ScreenLockPortrait';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as Colors from '../../res/values/Colors'
import { CANCELLED, CLOSED } from '../../constants/CaseStatuses';
import { CREATE_CASE_SECTION_ID } from '../../constants/GlobalConstants';
import * as R from 'ramda'

const classes = {
  actionButtons: {
    cursor: 'pointer',
    display: 'inline-block',
    color: "primary",
    marginLeft: '10px'
  },
  headerText: {
    float: 'left',
    marginLeft: '15px',
    color: Colors.SECTION_HEADING_DARK
  }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2b94c9",
    color: theme.palette.common.white,
    marginTop: '10px',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

class SectionHeader extends React.Component {

  render() {
    const { props } = this;
    const { sectionDetails, onEditClick,onRefreshClick, amendButtonStatus, userActions,
      caseStatusId, sectionLockBl, sectionLockedByName,isShowSectionDataRefreshIcon } = props;
    var canUserViewEditButton = userActions?.userActions?.filter(element => element.userActionId == 2);
    var isAllowed = R.path([0, 'allowedActionBl'], canUserViewEditButton);
    
    if (sectionDetails.sectionId != CREATE_CASE_SECTION_ID) {
      return (
        <div>
          <Typography variant="h6"
            style={classes.headerText}
          >{sectionDetails.sectionName}</Typography>
          { !sectionLockBl && !isShowSectionDataRefreshIcon && isAllowed && !amendButtonStatus 
          && !(caseStatusId == CLOSED || caseStatusId == CANCELLED) &&
            <EditIcon
              onClick={onEditClick}
              style={classes.actionButtons}
            />
          }
          {sectionLockBl && !isShowSectionDataRefreshIcon &&
            <LightTooltip title={"The Page is currently being edited by " + sectionLockedByName} placement="top" >
              <ScreenLockPortraitIcon style={classes.actionButtons}>
              </ScreenLockPortraitIcon>
            </LightTooltip>
          }
           {isShowSectionDataRefreshIcon &&
           <LightTooltip title={"Data has been changed. Please click to get refresh case information." } placement="top" >
              <RefreshIcon onClick={onRefreshClick} style={classes.actionButtons}>
              </RefreshIcon>
            </LightTooltip>
           }
        </div>
      );
    }
    else {
      return (
        <>
          <Typography variant="subtitle1"
            style={classes.headerText}
          >{sectionDetails.sectionName}</Typography>
        </>
      );
    }
  }
}


export default SectionHeader;

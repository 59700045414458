import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Grid, Typography, Button, Menu, MenuItem, Avatar, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Logo from "../../res/images/app_logo.png";
import Dashboard_Search_Icon from "../../res/images/dashboard_search_icon.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { loginUserData } from "../../reducers/UserReducer";
import LocalStorageProvider from "../../providers/LocalStorageProvider";
import { useHistory } from "react-router-dom";
import Logout from "../../services/Logout";
import DashboardFieldComponent from '../common/DashboardFieldComponent';
import { dashboardSearchBl, getDashboardCases, clearDashboardData } from '../../actions/DashboardAction'
import moment from 'moment';
import { getHeaderInformation} from '../../reducers/CaseDetailsReducer';
import {updateHeaderInformation, updateCaseEditableMode, sendSectionReleaseInfo, removeUserFromgroup } from '../../actions/CaseDetailsAction';
import * as constant from '../../constants/GlobalConstants';
import CaseDialogBox from '../case/CaseDialogBox';
import { getSessionAlertStatus} from "../../reducers/SectionAlertReducer"
import { sessionToggleDialog } from "../../actions/SectionAlertAction";
import { isNil, isEmpty, props } from 'ramda'
import { notify } from '../../actions/SnackbarAction'
import { isNumeric } from '../../utils/CommonFunctions'
import AuthAction from "../../actions/AuthActions";
import * as ActionTypes from '../../constants/ActionTypes'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { clearTenantSessionStorage } from "../../services/TenantService";
import {getUserInfo} from "../../actions/AuthActions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    width: "100%",
    zIndex: "1000",
    height: theme.spacing(8),
  },
  user: {
    display: "flex",
    alignItems: "center",
  },
  username: {
    color: "#19689C",
    textTransform: "uppercase",
    padding: `0px ${theme.spacing(1)}`,
  },
  userCaption: {
    color: "black",
    textTransform: "uppercase",
    textAlign: "right",
    padding: `0px ${theme.spacing(1)}`,
  },

  logo: {
    // width: theme.spacing(5),
    height: `61px`,
    // margin: `20px`,
  },
  dashboardSearchIcon: {
    //height: theme.spacing(4),
    width: '80px'//theme.spacing(12)
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  usericon: {
    color: "#fff",
    //border:"1px solid #2b94c9",
    //backgroundColor: "#2b94c9 ",

  },
  menu: {
        //marginTop: "45px",
    display: "flex",
  },
  menuItem: {
    padding: "10px 20px 10px 20px",
    color: "#19689C", 
},
  menuItemDisabled: {
    backgroundColor: '#e0e0e0',  
    padding: "10px 120px 10px 20px",
    color: "#19689C", 
    pointerEvents: 'none',  
  },
  search: {
    width: "93%",
    height: "auto",
    zIndex: "1200200",
    border: "4px lightgrey solid",
    backgroundColor: "#fff",
    //position: "absolute",
    borderRadius: "4px",
    top: "100%",
    marginLeft: theme.spacing(12),
  },
  closeButton: {
    marginLeft:'1em'
  },
  tenantIcon: {
    width: 40,
    height: 40,
    marginRight: 10,
    borderRadius: '50%',
  }
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2b94c9",
    color: theme.palette.common.white,
    marginTop: '10px',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const options = {
  closeOnOverlayClick: false,
  labels: {
    confirmable: "OK",
    cancellable: "CANCEL"
  }
}

function Header({ loginUserData, dashboardSearchFilters, loadedSectionId, clearDashboardData, isCaseEditable, error}) {

  const classes = useStyles();
  const tenantList = useSelector((state) => state.user.userData);
  const [tenantAnchorEl, setTenantAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSearchDiv, setOpenSeacrhDivBl] = useState(false);
  const dispatch = useDispatch();
  var headerInformation = useSelector(getHeaderInformation);
  const userImageUrl= LocalStorageProvider.getTenantIconUrl();
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearch = (event) => {
    if (event && !openSearchDiv) {
      setOpenSeacrhDivBl(true);
    }
    else {
      setOpenSeacrhDivBl(false);
      resetDashboardSearch();
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setTenantAnchorEl(null);
  };

  const resetDashboardSearch=()=>{
    dashboardSearchFilters.fieldPermissions.map((element)=>{
      element.value=null;

    })

  }

  useEffect(()=>{
    if(error && error.data && error.data.status == 400){
      dispatch(notify(
        '',
        constant.ERR_INVALID_INPUT,
        'error',
      ))

   }else if(error && error.data){  
      dispatch(notify(
        '',
        constant.ERR_SOMETHING_WENT_WRONG,
        'error',
      ))

    }
    // else if(!error && dashboardCases !=null){
    //   history.push(constant.DASHBOARD_PAGE_URL);
    // }
  },[error])

  const showSearchedCases = (e) => {

    var ItemPerPage = 100;
    var PageNumber = 1;
    var startDate = null;
    var endDate = null;
    var patientId = null;
    var caseId = null;
    var mrn = null;
    var primarySurgeonId = null;
    var surgicalLocationId = null;
    var caseStatusId = null;
    var patientDOB = null;
    var hasAnyInput = false;

    dashboardSearchFilters.fieldPermissions.forEach(function (element) {
      if (element.fieldMappingId == "PatientFullName") {
        if (element.value != undefined && element.value != "") {
          patientId = element.value
          hasAnyInput = true
        }
      }
      if (element.fieldMappingId == "CaseId") {
        if (element.value != undefined && element.value != "") {
          caseId = element.value
          if (isNumeric(caseId) && caseId != 0)
            hasAnyInput = true
          else
            dispatch(notify(
              '',
              constant.ERR_INVALID_INPUT,
              'error',
            ))

        }
      }
      if (element.fieldMappingId == "HospitalMRN") {
        if (element.value != undefined && element.value != "") {
          mrn = element.value
          hasAnyInput = true
        }
      }
      if (element.fieldMappingId == "SurgicalLocationId") {
        if (element.value != undefined && element.value != "") {
          surgicalLocationId = element.value.surgicalLocationId.toString();
          hasAnyInput = true
        }
      }
      if (element.fieldMappingId == "PrimarySurgeonId") {
        if (element.value != undefined && element.value != "") {
          primarySurgeonId = element.value.surgeonId
          hasAnyInput = true
        }
      }
      if (element.fieldMappingId == "PatientDOB") {
        if (element.value != undefined && element.value != "") {
          patientDOB = moment(element.value).format(constant.DASHBOARD_DATE_FORMAT);
          hasAnyInput = true
        }
      }
    })
    if (!hasAnyInput) {
      return
    }
    setOpenSeacrhDivBl(false)
    dispatch(dashboardSearchBl(true))
    dispatch(getDashboardCases(ItemPerPage, PageNumber, startDate, endDate, false, false, caseStatusId, patientId, caseId, mrn, primarySurgeonId, surgicalLocationId, patientDOB, "searchClick"))
      .then((data) => {
        history.push(constant.DASHBOARD_PAGE_URL);
      });
    resetDashboardSearch();

  }

  const multiTenantAction = payload => {
    return {
        type: ActionTypes.LOGIN_MULTITENANTS,
        payload,
        time: new Date()
    }
}

  useEffect(()=>{
    if (sessionStorage.getItem("tenants")) {
      let payload = sessionStorage.getItem("tenants")
      dispatch(multiTenantAction(JSON.parse(payload)));
    } 
  },[])


  const closeSearchDiv = () => {
    setOpenSeacrhDivBl(false)
    resetDashboardSearch();
  }

  const history = useHistory();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [sessiondDialogOpen, setSessionDialogOpen] = React.useState(false);
  const sessionAlertStatus=useSelector(getSessionAlertStatus)


  const askForConfirmation = () => {
    setDialogOpen(true);
  };

  useEffect(()=>{
    if(sessionAlertStatus){
      setSessionDialogOpen(true);
    }
  },[sessionAlertStatus])

  const onCancel = () => {
    setDialogOpen(false);
  };

  const onConfirm = () => {
    dispatch(sendSectionReleaseInfo());
    dispatch(removeUserFromgroup());
    dispatch(updateCaseEditableMode(false));
    clearDashboardData();
    dispatch(updateHeaderInformation(null));
    Logout(constant.SESSION_TIME_OUT);
    history.push(constant.LOGIN_PAGE_URL);
    onCancel();
  };


  const onSessionConfirm = () => {

    Logout(constant.IDLE);
    history.push(constant.LOGIN_PAGE_URL);
    dispatch(sessionToggleDialog(false));
    //sessionStorage.clear();
  };


  const openDialog = (
    <CaseDialogBox
      open={dialogOpen}
      dialogTitle = "Reject Process"
      dialogDescription = {constant.NAVIGATION_WARNING_MESSAGE}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
  const sessionOpenDialog = (
    <CaseDialogBox
      open={sessiondDialogOpen}
      dialogTitle = "Reject Process"
      dialogDescription = {constant.SESSION_WARNING_MESSAGE}
      onConfirm={onSessionConfirm}

    />
  )
  const handleUserLogout = async () => 
  {
    if(isCaseEditable)
    {
        askForConfirmation();
    }
    else
      {
        dispatch(sendSectionReleaseInfo());
        dispatch(removeUserFromgroup()); 
        dispatch(updateCaseEditableMode(false));
        clearDashboardData();
        dispatch(updateHeaderInformation(null));
        Logout(constant.BLANK_VALUE);
        history.push(constant.LOGIN_PAGE_URL);
      }
  }

  const handleTenantSwitch = (event) => {
    setTenantAnchorEl(event.currentTarget);
  };

  const handleTenantSelect = async (tenant) => {
    dispatch(AuthAction.saveTenantInfoOnSelection(tenant));
    await AuthAction.getUserInfo();
    clearTenantSessionStorage(); 
    setTenantAnchorEl(null);
    setAnchorEl(null);
    let redirectURI = window.location.href.split("//")
    window.location.href = `${redirectURI[0]}//${redirectURI[1].split("/")[0]}/dashboard`
  }

  const menuId = "user_menu";
  const dashboardSearch = "dashboard_search";
  const currentTenant = sessionStorage.getItem('LOGIN_TENANT_KEY');

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.menu}
    >
      {tenantList.length >= 2 && (
  <MenuItem onClick={handleTenantSwitch} className={classes.menuItem}>
    Switch Tenant
    <ArrowForwardIosRoundedIcon style={{ marginLeft: '60px', fontSize: '16px' }} />
  </MenuItem>
)}
      <MenuItem onClick={handleUserLogout} className={classes.menuItem}>
        Logout
      </MenuItem>

      <Menu
        anchorEl={tenantAnchorEl}
        open={Boolean(tenantAnchorEl)}
        onClose={() => setTenantAnchorEl(null)}  
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',  
        }}
        className={classes.tenantMenu}
      >
        {tenantList.map((tenant, index) => (
          
          <LightTooltip title={tenant.TenantName} placement="bottom" >
          <MenuItem
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              if (tenant.TenantKey !== currentTenant) { 
                handleTenantSelect(tenant);
              }
            }}
            className={tenant.TenantKey === currentTenant ? classes.menuItemDisabled : classes.menuItem}
            selected={tenant.TenantKey === currentTenant}
          >
            <img
              src={tenant.TenantIconUrl} 
              alt={tenant.TenantName}
              className={classes.tenantIcon}
            />
            {tenant.TenantName}
          </MenuItem>
          </LightTooltip>
        ))}
      </Menu>
    </Menu>
  );

  return (
    <AppBar className={classes.root}>
            {sessionAlertStatus ? <>{sessionOpenDialog}</>:<>{openDialog}</>}
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center">
          <Grid item>
            <img src={Logo} className={classes.logo} alt="" />
          </Grid>

          <Grid item sm style={{ marginLeft: '10px', width: '250px' }}>
            {headerInformation &&
              <>
                {headerInformation.patientFullName && <Typography noWrap style={{ color: 'black', fontSize: '10px', fontFamily: 'roboto' }}>{constant.PATIENTNAME}: <b>{headerInformation.patientFullName}</b></Typography>}
                {headerInformation.hospitalMRN && <Typography style={{ color: 'black', fontSize: '10px', fontFamily: 'roboto' }}>{constant.HOSPITALMRN} : <b>{headerInformation.hospitalMRN}</b></Typography>}
                {headerInformation.caseId && <Typography style={{ color: 'black', fontSize: '10px', fontFamily: 'roboto' }}>{constant.CASEID} : <b>{headerInformation.caseId}</b></Typography>}
                {headerInformation.procedureDate && <Typography style={{ color: 'black', fontSize: '10px', fontFamily: 'roboto' }}>{constant.PROCEDUREDATE} : <b>{moment(new Date(headerInformation.procedureDate)).format("MM/DD/YYYY")}</b></Typography>}
              </>
            }
            <Grid item sm>
              {(isNil(loadedSectionId) || isEmpty(loadedSectionId)) &&
                <div style={{ float: "right" }}>
                  <Button
                    edge="end"
                    aria-label="Dashboard Search"
                    aria-controls={dashboardSearch}
                    aria-haspopup="true"
                    onClick={handleSearch}
                  >
                    <img src={Dashboard_Search_Icon} className={classes.dashboardSearchIcon} alt="" />
                  </Button>
                </div>
              }
            </Grid>
          </Grid>
          <Grid item>
            <LightTooltip title="Click here to logout" placement="bottom" >

              <Button
                className={classes.user}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
              >
                <Typography
                  variant="h6"
                  component="span"
                  style={{ "fontSize": "1.125rem" }}
                  className={classes.username}
                >
                  {LocalStorageProvider.getLoginUserDisplayName()}
                </Typography>
                <Avatar className={classes.usericon} alt={LocalStorageProvider.getLoginUserDisplayName()} src={userImageUrl} />
              </Button>
            </LightTooltip>

            {/* <Typography paragraph className={classes.userCaption}>
              (Stage-MS)
            </Typography> */}
            {renderMenu}
          </Grid>
        </Grid>
      </Toolbar>
      {openSearchDiv &&
        <div className={classes.search}>
          <Grid container spacing={3} style={{ padding: "0.5%" }}>
            {dashboardSearchFilters && dashboardSearchFilters.fieldPermissions.map((item, index) => {
              return <Grid item xs={6} key={index}>
                <DashboardFieldComponent
                  key={item.fieldName}
                  control={item}
                  fieldType={item.fieldControlName}
                /> </Grid>
            })}
            <Button variant="text" size="medium" color="primary" className={classes.closeButton} onClick={closeSearchDiv}>Close</Button>
            <Button variant="text" size="medium" color="primary" onClick={showSearchedCases}>Search</Button>
          </Grid>
        </div>
      }
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  loginUserData: loginUserData(state),
  dashboardSearchFilters: state.dashboard.dashboardFiltersData,
  loadedSectionId: state.caseDetails.loadedSectionId,
  isCaseEditable: state.caseDetails.isCaseEditable,
  error: state.dashboard.error,
});
export default connect(mapStateToProps, { dashboardSearchBl, getDashboardCases, clearDashboardData })(Header);

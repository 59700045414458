import React, { Children } from 'react';
import { connect } from 'react-redux';
import { Divider, Typography, Grid } from '@mui/material';
import FieldComponent from '../common/FieldComponent';
import { DELETE_POSITION_AND_BED_TYPE } from '../../constants/GlobalConstants';
import * as Colors from '../../res/values/Colors'
import PacketTypeInformationRow from '../case/PacketTypeInformationRow';
import Controls from '../controls/Controls';
import { Add } from '@mui/icons-material';
import { getSelectionsForControl, getPopupSectionDetails, getDocumentUploadPopUpDetails, getDocumentSelectionControls,
  getViewDocDetails, getPreviewAvailable
} from '../../reducers/CaseDetailsReducer';
import {
  updateControlValue, clearPopupStoreData, executeDocumentUploadAPI, parseDocumentByCaseIdDocId
} from '../../actions/CaseDetailsAction';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import * as Constant from '../../constants/GlobalConstants';
import moment from 'moment';
import { validateDate } from '../../utils/ruleEngine/ValidationRules/DateValidations';
import { renderViewFile } from '../controls/DocumentViewer';
import {
  LinearProgress
} from '@mui/material';
import { renderImage, createBlobFromBase64 } from '../../utils/rendertypes';
import TiffPreview from './TiffPreview'

const classes = {
  previewGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  embed: {
    height: '100%',
    width: '100%'
  },
  redFont: {
    color: Colors.EXPIRED_DOC_TEXT
  },
  tiffContainer: {
    maxWidth: '100%',
    overflow: 'hidden'
  }
};

const mapStateToProps = state => ({
  controlSelections: getDocumentSelectionControls(state),
  popupSectionDetails: getDocumentUploadPopUpDetails(state),
  fileData: getViewDocDetails(state),
  previewStatus: getPreviewAvailable(state)
})

const mapDispatchToProps = dispatch => ({
  closePopUp: () =>
    dispatch(clearPopupStoreData()),
  updateControlValue: (mappingId, value, parentFieldId) =>
    dispatch(updateControlValue(mappingId, value, parentFieldId)),
  uploadDocument: (serviceAPIName, fieldData, setRespons) =>
    dispatch(executeDocumentUploadAPI(serviceAPIName, fieldData, setRespons)),
  parseDocument: (caseId, docId, data) =>
    dispatch(parseDocumentByCaseIdDocId(caseId, docId, data)), 
})

class SectionGroupComponentDialogBoxUploadDoc extends React.Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.memoizedObjectURL = null;
  }

  // Setting up the initial state for the document upload form
  getInitialState = () => {
    return {
      docInfo: {},
      signatureDate: null,
      docDescription: "",
      docNotes: "",
      rowInformationArray: [
        {
          docType: "",
          pageRange: "",
          signedDate: null
        }
      ],
      docFile: "",
      packetIncludesVisible: false,
      supportedFileType: [],
      selectedChildTypes: [],
      docPreviewBl: false,
      selectedFile: "",
      parseDocBl: false,
      initialEdit: true,
      editPreview: "",
      validateSection: {
        docInfo: { status: true, message: '' },
        signatureDate: { status: true, message: '' },
        invalidDate: false,
        docFile: { status: false, message: '' },
        packetIncludes: [
          {
            docInfo: { status: true, message: '' },
            pageRange: { status: true, message: '' },
            signDate: { status: false, message: '' },
          }
        ]
      }
    };
  }

  // Getting document type details by document type id
  getDocTypeDetails = (docTypeId) => {
    const docTypeData = this.props.controlSelections.DocumentTypes.filter(item => item.DocumentTypeId == docTypeId)
    return docTypeData[0]
  }

  // Getting packet include details to render in edit view
  getPacketIncludeDetails = (packetIncludes) => {
    const packetArr = packetIncludes.reduce((acc, item) => {
      const obj = {
        docType: this.getDocTypeDetails(item.PacketDocumentTypeId),
        pageRange: item.PacketPageRange,
        signedDate: item.PacketDocumentSignedDate ?  moment(item.PacketDocumentSignedDate).format(Constant.COMMON_DATE_FORMAT) : item.PacketDocumentSignedDate
      }
      acc.push(obj)
      return acc
    }, [])
    return packetArr
  }

  // This is used to update state if section is edit document
  componentDidMount(){
    if(this.props.popupSectionDetails?.sectionHeader?.sectionName == Constant.EDIT_DOC_SECTION_NAME) {
      const documentData = this.props.popupSectionDetails.sectionData.document;
      const { validateSection } = this.state
      const { fileData, controlSelections } = this.props
      const docTypeDetails = this.getDocTypeDetails(documentData.selectedDocumentTypeId)
      const packetIncludes = JSON.parse(documentData.packetDetails)
      const packetIncludeData = this.getPacketIncludeDetails(packetIncludes)
      let childTypeSelected = []
      const validationPacketIncludes = packetIncludeData.map((item, idx) => {
        let signedDateStatus = { status: false, message: '' }
        childTypeSelected = this.addSelectedChildDocument(item.docType, idx)
        if(packetIncludes[idx].PacketDocumentExpiredBl == true) {
          let beforeEncounterDateMsg = Constant.DOC_HNP_GT_MSG.replace(/<d>/g, item.docType.ExpirationTimeFrameInDays)
          beforeEncounterDateMsg = beforeEncounterDateMsg.replace(/<doctype>/g, item.docType.DocumentTypeName)
          signedDateStatus = {
            status: true,
            message: beforeEncounterDateMsg
          }
        }
        const obj = {
          docInfo: { status: false, message: '' },
          pageRange: { status: false, message: '' },
          signDate: signedDateStatus,
        }
        return obj
      })

      const validateSectionObj = {...validateSection, docInfo: { status: false, message: '' }, packetIncludes: validationPacketIncludes}
      
      if(Object.keys(this.state.docInfo).length == 0){
        this.setState({
          docInfo: docTypeDetails,
          docDescription: documentData.docDetailsDescription,
          docNotes: documentData.docDetailsNotes,
          rowInformationArray: packetIncludeData,
          validateSection: validateSectionObj,
          selectedFile: fileData,
          docPreviewBl: true,
          initialEdit: false,
          selectedChildTypes: childTypeSelected,
          packetIncludesVisible: true,
        })
        this.updatePreview(fileData);
      }
    }
  }

  //This is used to update file details if previous and current state are not same
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.previewStatus !== this.props.previewStatus) {
      this.setState({selectedFile: this.props.fileData})
    }
    if (prevState.selectedFile !== this.state.selectedFile) {
      this.updatePreview(this.state.selectedFile);
    }
  }

  //memoize the object URL for file preview
  memoize = (fn) => {
    return () => {
      if (!this.memoizedObjectURL) {
        this.memoizedObjectURL = fn();
      }
      return this.memoizedObjectURL;
    };
  }

  // Getting object URL for file preview
  getObjectURL = () => {
    if (this.state.docFile) {
      return URL.createObjectURL(this.state.docFile);
    }
    return null; // or some default value if docFile is null or undefined
  }

  // Getting supported file types when document type is packet
  getSupportedFileTypes = (isPacketBl) => {
    let supportedFileTypeExt = []

    if(isPacketBl) {
      if(this.props.controlSelections?.DocumentTypes) {
        const supportedFileTypeId = this.props.controlSelections.DocumentTypes.filter(item => item.IsPacketBl == true)
        supportedFileTypeExt = this.props.controlSelections.DocumentSupportedFileTypes.reduce((acc, item) => {
          if(supportedFileTypeId[0].SupportedFileTypes.includes(item.DocumentSupportedFileTypeId)) {
            acc.push(item.DocumentSupportedFileTypeName)
          }
          return acc
        }, [])
      }
    } else {
      supportedFileTypeExt = this.props.controlSelections?.DocumentSupportedFileTypes.map(item => item.DocumentSupportedFileTypeName)
    }

    return supportedFileTypeExt
  }

  //Handling the form state update
  handleStateUpdate = (key, value) => {
    const { validateSection, signatureDate, docInfo } = this.state;
    const { hfProcedureDate } = this.props.popupSectionDetails.sectionData
    const { DocumentTypes } = this.props.controlSelections
    const hnpDocTypeDetails = DocumentTypes.filter(item => (item.ExpirationTimeFrameBl == true && item.DocumentTypeId == docInfo.DocumentTypeId))

    if (value) {
      this.setState({initialEdit: true})
      if (key == 'signatureDate') {
        const date = moment(value).format(Constant.COMMON_DATE_FORMAT)
        const isValidDate = validateDate(value)
        const pastDate = moment(hfProcedureDate, Constant.COMMON_DATE_FORMAT).subtract(hnpDocTypeDetails[0].ExpirationTimeFrameInDays, 'days').format(Constant.COMMON_DATE_FORMAT)
        const isBeforeEncounterDate = moment(date).isBefore(pastDate)
        let beforeEncounterDateMsg = Constant.DOC_HNP_GT_MSG.replace(/<d>/g, hnpDocTypeDetails[0].ExpirationTimeFrameInDays)
        beforeEncounterDateMsg = beforeEncounterDateMsg.replace(/<doctype>/g, hnpDocTypeDetails[0].DocumentTypeName)
        const currDate = moment()
        const isAfterCurrentDate = moment(date).isAfter(currDate)

        if (isValidDate) {
          this.setState({ signatureDate: date, validateSection: { ...validateSection, invalidDate: true, signatureDate: { status: true, message: isValidDate.message } } })
        } else if(isBeforeEncounterDate) {
          this.setState({ signatureDate: date, validateSection: { ...validateSection, invalidDate: false, signatureDate: { status: true, message: beforeEncounterDateMsg } } })
        } else if(isAfterCurrentDate) {
          this.setState({ signatureDate: date, validateSection: { ...validateSection, invalidDate: true, signatureDate: { status: true, message: Constant.AFTER_DATE_MSG } } })
        } else {
          this.setState({ signatureDate: date, validateSection: { ...validateSection, invalidDate: false, signatureDate: { status: false, message: '' } } })
        }
      } else if (key == 'docFile') {
        const fileInfo = value.target.files[0]

        if (fileInfo) {
          const fileType = fileInfo.name.split('.');
          const fileTypeExt = `.${fileType[fileType.length - 1]}`
          const fileExt = fileTypeExt.toLowerCase()
          const supportFileType = this.getSupportedFileTypes(docInfo.IsPacketBl)
          const previweFileType = Constant.FILE_TYPES_FOR_PREVIEW.includes(fileExt)

          if (supportFileType.includes(fileExt)) {
            const initState = this.getInitialState()
            this.memoizedObjectURL = null;
            this.setState({
              docFile: fileInfo,
              packetIncludesVisible: true,
              validateSection: { ...validateSection, docFile: { status: false, message: '' }, packetIncludes: initState.validateSection.packetIncludes },
              docPreviewBl: previweFileType,
              rowInformationArray: initState.rowInformationArray,
              selectedChildTypes: initState.selectedChildTypes
            });
          } else {
            this.memoizedObjectURL = null;
            this.setState({
              docFile: fileInfo,
              packetIncludesVisible: false,
              validateSection: { ...validateSection, docFile: { status: true, message: Constant.INVALID_FILE_MSG }},
              supportedFileType: supportFileType,
              docPreviewBl: previweFileType
            });
          }
        } else {
          this.memoizedObjectURL = null;
          this.setState({
            docFile: "",
            packetIncludesVisible: false,
            validateSection: { ...validateSection, docFile: { status: false, message: '' } }
           });
        }
      } else {
        this.setState({ [key]: value, validateSection: { ...validateSection, [key]: { status: false, message: '' } } });
      }
    } else {
      let fieldVal = value;
      if (key == 'docInfo') {
        fieldVal = {}
      }
      this.setState({ [key]: fieldVal, validateSection: { ...validateSection, [key]: { status: true, message: '' } } })
    }
  }

  //This function is used to render active/inactive upload button in the dialog box
  getActiveUpload = () => {
    const { rowInformationArray, docFile, docInfo, signatureDate, validateSection, initialEdit } = this.state;
    const { popupSectionDetails } = this.props
    const isEditSection = popupSectionDetails?.sectionHeader.sectionName == Constant.EDIT_DOC_SECTION_NAME
    let disabled = true;

    if(initialEdit) {
      if ((isEditSection || docFile) && Object.keys(docInfo).length > 0 && (isEditSection || !validateSection.docFile.status)) {
        if (docInfo.IsPacketBl) {
          // const isIncludesValid = validateSection.packetIncludes.some(packet => 
          //   packet.docInfo.status === true ||
          //   packet.pageRange.status === true ||
          //   packet.signDate.status === true
          // );
          const isIncludesValid = validateSection.packetIncludes.some(packet => 
            packet.docInfo.status === true ||
            packet.pageRange.status === true
          );
            if(!validateSection.invalidDate){
              if (rowInformationArray[0].docType !== "" && rowInformationArray[0].pageRange !== "" && !isIncludesValid) {
                disabled = false;
              }
            }
          
        } else if (docInfo.ExpirationTimeFrameBl) {
          if(!validateSection.invalidDate) {
            const onExpireDisable = this.getFieldProperties(Constant.DOC_UPLOAD_BTN_MAPPING_ID)
            if (signatureDate !== null && (!onExpireDisable.additionalProperties.OnDocumentExpirationDisableUploadBl || !validateSection.signatureDate.status)) {
              disabled = false;
            }
          }
          
        } else {
          disabled = false;
        }
      }
    } else {
      disabled = false;
    }

    return disabled;
  }

  //This function is used to add packet includes component
  addPacketRow = () => {
    let value = {
      docType: "",
      pageRange: "",
      signedDate: null
    };
    const { rowInformationArray, validateSection } = this.state
    const packetIncludes = [...validateSection.packetIncludes, {
        docInfo: { status: true, message: '' },
        pageRange: { status: true, message: '' },
        signDate: { status: false, message: '' },
    }]
    const updatedArray = [...rowInformationArray, value]

    this.setState({
      rowInformationArray: updatedArray,
      validateSection: {...validateSection, packetIncludes: packetIncludes }
    });
  }

  //Remove packet includes row
  deleteRow = (indexToDelete) => {
    const { rowInformationArray, validateSection, selectedChildTypes } = this.state
    const newArray = rowInformationArray.filter((_, index) => index != indexToDelete)
    validateSection.packetIncludes.splice(indexToDelete, 1)

    if(selectedChildTypes[indexToDelete]) {
      selectedChildTypes.splice(indexToDelete, 1)
    }

    this.setState({
      rowInformationArray: newArray,
      validateSection: {...validateSection, packetIncludes: validateSection.packetIncludes },
      selectedChildTypes: selectedChildTypes
    });
  }

  // Used to identifiy unique array compare for page range
  hasUniqueNumbersAcrossSubarrays = (arr) => {
    const flattenedArray = arr.flat();
    const uniqueNumbers = new Set();
    for (const number of flattenedArray) {
      if (uniqueNumbers.has(number)) {
        return false;
      }
      uniqueNumbers.add(number);
    }
    // If no duplicates were found, return true
    return true;
  }

  // Validating page range here
  validatePageRange = (value) => {
    let rangeInfo = {status: true, message: ''}
    const { rowInformationArray } = this.state
    const pageRangeArr = []
    rowInformationArray.map(item => {
      if(item.pageRange) {
        const numbers = item.pageRange.match(/\d+/g).map(Number);
        pageRangeArr.push(numbers)
      }
    })

    
    const isValidPageRange = this.hasUniqueNumbersAcrossSubarrays(pageRangeArr)

    if(value) {
      rangeInfo.status = false;
      rangeInfo.message = ''
    }

    if(!isValidPageRange) {
      rangeInfo.status = true;
      rangeInfo.message = Constant.INVALID_PAGE_RANGE_MSG
    }

    return rangeInfo;
  }

  // Resetting packet includes state
  resetPacketIncludeState = (values, type, inx) => {
    const { rowInformationArray, validateSection } = this.state
    const packetIncludes = validateSection.packetIncludes
    rowInformationArray[inx] = {
      docType: "",
      pageRange: "",
      signedDate: null
    }
    
    packetIncludes[inx] = {
      docInfo: { status: false, message: '' },
      pageRange: { status: false, message: '' },
      signDate: { status: false, message: '' },
    }

    const packetIncludeState = {
      ...this.state,
      rowInformationArray: rowInformationArray,
      validateSection: {...this.state.validateSection, packetIncludes: packetIncludes}
    }

    this.setState(packetIncludeState, () => {
      this.handleInputChange(values, type, inx)
    })
  }

  // This function is used to update state for child doc type selection
  addSelectedChildDocument = (item, idx) => {
    const { selectedChildTypes } = this.state
    if(item) {
      const childDocInfo = {typeId: item.DocumentTypeId, status: true}
      if(selectedChildTypes[idx]) {
        selectedChildTypes[idx] = childDocInfo
      } else {
        selectedChildTypes.push(childDocInfo)
      }
    } else {
      selectedChildTypes[idx].status = false
    }
    
    return selectedChildTypes
  }

  // This function is used to set state of dialog form
  handleInputChange(values, fieldType, index) {
    let docValue = this.state.rowInformationArray;
    const { validateSection } = this.state
    const { hfProcedureDate } = this.props.popupSectionDetails.sectionData
    const { DocumentTypes } = this.props.controlSelections
    docValue[index][fieldType] = fieldType === "docType" ? { ...values } : values;

    const selectedChildDocuments = fieldType === "docType" ? this.addSelectedChildDocument(values, index) : this.state.selectedChildTypes;
    const validatePacketIncludes = validateSection.packetIncludes
    let incorrectDate = false
    docValue.map((item, inx) => {
      const isPageRange = this.validatePageRange(item.pageRange)
      validatePacketIncludes[inx].docInfo = item.docType?.DocumentTypeId ? { status: false, message: '' } : { status: true, message: '' }
      validatePacketIncludes[inx].pageRange = isPageRange
      validatePacketIncludes[inx].signDate = { status: false, message: '' }

      if(item.docType.ExpirationTimeFrameBl) {
        const pastDate = moment(hfProcedureDate, Constant.COMMON_DATE_FORMAT).subtract(item.docType.ExpirationTimeFrameInDays, 'days').format(Constant.COMMON_DATE_FORMAT)
        const isBeforeEncounterDate = moment(item.signedDate).isBefore(pastDate)
        let beforeEncounterDateMsg = Constant.DOC_HNP_GT_MSG.replace(/<d>/g, item.docType.ExpirationTimeFrameInDays)
        beforeEncounterDateMsg = beforeEncounterDateMsg.replace(/<doctype>/g, item.docType.DocumentTypeName)
        const currDate = moment()
        const isAfterCurrentDate = moment(item.signedDate).isAfter(currDate)
        const isValidDate = validateDate(item.signedDate)

        if(isValidDate) {
          validatePacketIncludes[inx].signDate = { status: true, message: isValidDate.message }
          incorrectDate = true
        } else if(isBeforeEncounterDate) {
          validatePacketIncludes[inx].signDate = { status: true, message: beforeEncounterDateMsg }
        } else if(isAfterCurrentDate) {
          validatePacketIncludes[inx].signDate = { status: true, message: Constant.AFTER_DATE_MSG }
          incorrectDate = true
        } else if(item.signedDate == null) {
          validatePacketIncludes[inx].signDate = { status: true, message: '' }
          incorrectDate = true
        }
      }
    })

    this.setState({
      rowInformationArray: [...docValue],
      initialEdit: true,
      validateSection: {...this.state.validateSection, invalidDate: incorrectDate},
      selectedChildTypes: selectedChildDocuments
    });
  }

  // This function is used to upload document
  handleDocumentUpload() {
    const { docInfo, docDescription, docNotes, docFile, rowInformationArray, signatureDate } = this.state;
    const packetInfo = rowInformationArray.map(item => {
      const obj = {
        DocumentTypeId: item.docType.DocumentTypeId,
        PageRange: item.pageRange,
        DocumentDescription: docDescription,
        DocumentNote: docNotes,
        DocumentSignedDate: item.signedDate,
        DocumentExpirationDate: item.signedDate ? moment(item.signedDate, Constant.COMMON_DATE_FORMAT).add(item.docType.ExpirationTimeFrameInDays, 'days').format(Constant.COMMON_DATE_FORMAT) : null
      }
      return obj;
    })
    const packetDetails = docInfo.IsPacketBl === true ? JSON.stringify(packetInfo) : []
    const payload = {
      DocFileSelection: docFile,
      DocumentTypeId: docInfo.DocumentTypeId,
      DocumentSignedDate: signatureDate,
      DocumentExpirationDate: signatureDate ? moment(signatureDate, Constant.COMMON_DATE_FORMAT).add(docInfo.ExpirationTimeFrameInDays, 'days').format(Constant.COMMON_DATE_FORMAT) : null,
      DocDescription: docDescription,
      DocNotes: docNotes,
      IsPacketBl: docInfo.IsPacketBl,
      PacketDetails: packetDetails
    }

    this.props.uploadDocument("UploadDocument", payload, null)
  }

  //This function is used to update/parse document
  handleDocumentEdit = () => {
    const { docInfo, parseDocBl, docNotes, docDescription, rowInformationArray } = this.state
    const { popupSectionDetails: { sectionData: { document } }, caseId, parseDocument } = this.props

    const packetInfo = rowInformationArray.map(item => {
      const obj = {
        DocumentId: document.docDetailsDocumentId,
        DocumentTypeId: item.docType.DocumentTypeId,
        DocumentTypeName: '',
        PageRange: item.pageRange,
        DocumentDescription: docDescription,
        DocumentNote: docNotes,
        DocumentSignedDate: item.signedDate,
        DocumentExpirationDate: item.signedDate ? moment(item.signedDate, Constant.COMMON_DATE_FORMAT).add(item.docType.ExpirationTimeFrameInDays, 'days').format(Constant.COMMON_DATE_FORMAT) : null,
        DocumentExpiredBl: ''
      }
      return obj;
    })
    const packetDetails = JSON.stringify(packetInfo)
    var fileData = new FormData();
    fileData.append('DocumentParseBl', parseDocBl);
    fileData.append('CaseId', caseId);
    fileData.append('DocumentId', document.docDetailsDocumentId);
    fileData.append('DocumentTypeId', document.selectedDocumentTypeId);
    fileData.append('DocumentTypeName', document.selectedDocumentTypeName);
    fileData.append('DocumentSignedDate', '');
    fileData.append('DocumentExpirationDate', '');
    fileData.append('DocumentNote', docNotes);
    fileData.append('DocumentDescription', docDescription);
    fileData.append('DocumentExpiredBl', document.documentExpiredBl);
    fileData.append('IsPacketBl', true);
    fileData.append('PacketDetails', packetDetails);

    parseDocument(caseId, document.docDetailsDocumentId, fileData)
  }

  // Get field properties with mapping id for form fields
  getFieldProperties = (mappingId) => {
    const fieldInfo = {}
    const { popupSectionDetails } = this.props
    
    if(popupSectionDetails?.sectionHeader.sectionName == Constant.UPLOAD_DOC_SECTION_NAME
      || popupSectionDetails?.sectionHeader.sectionName == Constant.EDIT_DOC_SECTION_NAME) {
      const elementDetails = popupSectionDetails.sectionHeader.sectionGroup[0].fields.find(item => item.mappingId == mappingId)
      const additionalProperties = JSON.parse(elementDetails.additionalProperties)
      fieldInfo.element = elementDetails
      fieldInfo.additionalProperties = additionalProperties
    }
    return fieldInfo
  }

  // Updating preview state for edit
  updatePreview = (file) => {
    const preview = file ? renderViewFile(file) : null;
    this.setState({ editPreview: preview });
  };

  // This function is used to get file preview
  getFilePreview = () => {
    const { popupSectionDetails } = this.props
    const { selectedFile, editPreview, docFile } = this.state
    const memoizedGetObjectURL = this.memoize(this.getObjectURL);

    switch(popupSectionDetails?.sectionHeader.sectionName) {
      case Constant.EDIT_DOC_SECTION_NAME:
        if(selectedFile.documentOrignalFileExtension == '.tiff') {
          const blobUrl = this.memoize(() => createBlobFromBase64(selectedFile.fileStream, 'image/tiff'));
          return (
            <div style={classes.tiffContainer}>
              <TiffPreview file={blobUrl()}/>
            </div>
          )
        } else {
          return editPreview
        }
      case Constant.UPLOAD_DOC_SECTION_NAME:

        if(docFile.type == 'image/tiff') {
          return (
            <div style={classes.tiffContainer}>
              <TiffPreview file={memoizedGetObjectURL()}/>
            </div>
          )
        } else {
          return (
            <embed
              style={classes.embed}
              src={memoizedGetObjectURL()}
            />
          )
        }
    }
  }

  render() {
    const { props, getFieldProperties, getFilePreview } = this;
    const { others, isPopup, popupSectionDetails, controlSelections, previewStatus } = props;
    const { rowInformationArray, docFile, docInfo, docDescription, docNotes, signatureDate, validateSection,
      supportedFileType, docPreviewBl, selectedFile, parseDocBl, selectedChildTypes, packetIncludesVisible
    } = this.state;

    let subSelections = controlSelections ? controlSelections.DocumentTypes.filter(item => item.IsPacketBl == false) : [];

    if(subSelections.length > 0 && selectedChildTypes.length > 0) {
      const selectedDocTypes = selectedChildTypes.reduce((acc, item) => {
        if(item.status) {
          acc.push(item.typeId)
        }
        return acc
      }, [])
      subSelections = subSelections.filter(item => !selectedDocTypes.includes(item.DocumentTypeId))
    }

    const disabledUpload = this.getActiveUpload();
    let docFileMappingId = Constant.DOC_FILE_MAPPING_ID
    let docFileSaveMappingId = Constant.DOC_UPLOAD_BTN_MAPPING_ID
    let parseDocumentBlDetails = {}
    if(popupSectionDetails?.sectionHeader.sectionName == Constant.EDIT_DOC_SECTION_NAME) {
      docFileMappingId = Constant.DOC_FILE_SELECTION_EDIT_MAPPING_ID
      docFileSaveMappingId = Constant.DOC_FILE_SAVE_MAPPING_ID
      parseDocumentBlDetails = getFieldProperties(Constant.PARSE_DOC_MAPPING_ID)
    }
    
    const docTypeDetails = getFieldProperties(Constant.DOC_TYPE_MAPPING_ID)
    const signedDateDetails = getFieldProperties(Constant.DOC_SIGNED_DATE_MAPPING_ID)
    const docDescriptionDetails = getFieldProperties(Constant.DOC_DESCRIPTION_MAPPING_ID)
    const docNoteDetails = getFieldProperties(Constant.DOC_NOTES_MAPPING_ID)
    const docFileDetails = getFieldProperties(docFileMappingId)
    const uploadBtnDetails = getFieldProperties(docFileSaveMappingId)
    const cancelBtnDetails = getFieldProperties(Constant.DOC_CANCEL_BTN_MAPPING_ID)
    const pageRangeDetails = getFieldProperties(Constant.PAGE_RANGE_MAPPING_ID)
    const signedDateAdditionalProps = {...signedDateDetails?.additionalProperties, [Constant.DISABLE_FUTURE_DATE_KEY]: true}

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Grid item mb={2}>
                {Object.keys(docTypeDetails).length > 0 && (
                <Controls.AutoSuggest
                  label={docTypeDetails?.element?.fieldName}
                  onChange={(e, values) => {
                    this.setState(this.getInitialState(), () => {
                      this.handleStateUpdate('docInfo', values);
                      this.memoizedObjectURL = null;
                    });
                  }}
                  additionalProperties={docTypeDetails?.additionalProperties}
                  selections={controlSelections?.DocumentTypes}
                  value={docInfo}
                  error={validateSection.docInfo.status}
                  helperText={validateSection.docInfo.message}
                  disabled={docFileSaveMappingId == Constant.DOC_FILE_SAVE_MAPPING_ID}
                />
                )}
              </Grid>
              {(docInfo !== "" && docInfo.ExpirationTimeFrameBl === true) && (
                <Grid item mb={2}>
                  {Object.keys(signedDateDetails).length > 0 && (
                    <>
                  <Controls.DatePicker
                    label={signedDateDetails?.element?.fieldName}
                    fieldTypeName={signedDateDetails?.element?.fieldTypeName}
                    defaultFieldName={signedDateDetails?.element?.defaultFieldName}
                    fieldName={signedDateDetails?.element?.fieldName}
                    description={signedDateDetails?.element?.description}
                    additionalProperties={signedDateAdditionalProps}
                    onChange={(e) => {
                      this.handleStateUpdate('signatureDate', e)
                    }}
                    value={signatureDate}
                    error={validateSection.signatureDate.status}
                    helperText={validateSection.signatureDate.message}
                  />
                  {signatureDate && (
                    <Typography>{`${Constant.HNP_EXPIRE_DT_LBL} ${moment(signatureDate, Constant.COMMON_DATE_FORMAT).add(docInfo.ExpirationTimeFrameInDays, 'days').format(Constant.COMMON_DATE_FORMAT)}`}</Typography>
                  )}
                  
                  </>
                  )}
                </Grid>
              )}
              <Grid item mb={2}>
              {Object.keys(docDescriptionDetails).length > 0 && (
                <Controls.Input
                  name={docDescriptionDetails?.element?.fieldName}
                  label={docDescriptionDetails?.element?.fieldName}
                  maxLength={docNoteDetails?.additionalProperties?.MaxLength}
                  value={docDescription}
                  isRequired={false}
                  disabled={false}
                  onChange={(e) => this.setState({ docDescription: e.target.value })}
                />
              )}
              </Grid>
              <Grid item mb={2}>
              {Object.keys(docNoteDetails).length > 0 && (
                <Controls.Input
                  name={docNoteDetails?.element?.fieldName}
                  label={docNoteDetails?.element?.fieldName}
                  maxLength={docNoteDetails?.additionalProperties?.MaxLength}
                  value={docNotes}
                  isRequired={false}
                  disabled={false}
                  onChange={(e) => this.setState({ docNotes: e.target.value })}
                />
              )}
              </Grid>
              <Grid item mb={2} xs={12}>
              {Object.keys(docFileDetails).length > 0 && (
                <>
                  {(docFileSaveMappingId == Constant.DOC_FILE_SAVE_MAPPING_ID && popupSectionDetails?.sectionData?.document?.docDetailsFileName) ? (
                    <Typography>{`${Constant.LBL_SELECTED_FILE} ${popupSectionDetails?.sectionData?.document?.docDetailsFileName}`}</Typography>
                  ) : (
                    <Controls.File
                      name={docFileDetails?.element?.fieldName}
                      label={docFileDetails?.element?.fieldName}
                      value={this.state.docFile}
                      isShowFilePreview={false}
                      isRequired={true}
                      disabled={(docFileDetails?.element?.permissionTypeId == 5 || Object.keys(docInfo).length == 0)}
                      onChange={(e) => this.handleStateUpdate('docFile', e)}
                    />
                  )}
                
                {validateSection.docFile.status && (
                  <>
                  <Typography style={classes.redFont}>{validateSection.docFile.message}</Typography>
                  <Typography style={classes.redFont}>{`${Constant.SUPPORTED_FILE_TYPE_MSG} ${supportedFileType.join(", ")}`}</Typography>
                  </>
                )}
                </>
              )}
              </Grid>

              <Grid item xs={12}>
                {(docInfo !== "" && docInfo.IsPacketBl === true && packetIncludesVisible) && (
                  <>
                    <Controls.Typography
                      variant={"subtitle1"}
                      sx={{ fontWeight: "medium" }}
                    >
                      Packet Includes:
              </Controls.Typography>

                    {rowInformationArray.map((item, index) =>
                      <PacketTypeInformationRow
                        disabledDelete={rowInformationArray.length <= 1}
                        selections={subSelections}
                        validateIncludes={validateSection.packetIncludes[index]}
                        deleteSelectedRow={() => this.deleteRow(index)}
                        onInputChange={(values, fieldType) => this.handleInputChange(values, fieldType, index)}
                        onDocTypeChange={(values, fieldType) => this.resetPacketIncludeState(values, fieldType, index)}
                        values={this.state.rowInformationArray[index]}
                        signedDateDetails={signedDateDetails}
                        docTypeDetails={docTypeDetails}
                        pageRangeDetails={pageRangeDetails}
                        signedDateAdditionalProps={signedDateAdditionalProps}
                      />
                    )}
                    <Controls.Button
                      name={Constant.ADD_TYPE_TEXT}
                      startIcon={<Add />}
                      label={Constant.ADD_TYPE_TEXT}
                      variant="text"
                      disabled={false}
                      onClick={this.addPacketRow}
                    />
                  </>
                )}
              </Grid>
              {docFileSaveMappingId == Constant.DOC_FILE_SAVE_MAPPING_ID && (
                <Grid item mb={2} xs={12}>
                  <Controls.Checkbox
                    label={parseDocumentBlDetails?.element?.fieldName}
                    value={parseDocBl}
                    onChange={(e) => this.setState({parseDocBl: e.target.checked})}
                  />
                  <Typography>{Constant.PARSE_DOC_DESCRIPTION}</Typography>
                </Grid>
              )}
              <Grid item mb={2} display="flex">
              {Object.keys(uploadBtnDetails).length > 0 && (
                <Controls.Button
                  name={uploadBtnDetails?.element?.fieldName}
                  label={uploadBtnDetails?.element?.fieldName}
                  disabled={disabledUpload}
                  color="success"
                  variant="contained"
                  isDocManagement={true}
                  sx={{ borderRadius: '4px !important' }}
                  onClick={() => {
                    if(docFileSaveMappingId == Constant.DOC_UPLOAD_BTN_MAPPING_ID) {
                      this.handleDocumentUpload()
                    } else {
                      this.handleDocumentEdit()
                    }
                  }}
                />
              )}
              {Object.keys(cancelBtnDetails).length > 0 && (
                <Controls.Button
                  name={cancelBtnDetails?.element?.fieldName}
                  label={cancelBtnDetails?.element?.fieldName}
                  disabled={false}
                  isDocManagement={true}
                  onClick={() => this.setState(this.getInitialState(), () => {this.props.onCloseDialog()})}
                />
              )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={classes.previewGrid}>
            {previewStatus ? (
              ((docFile || selectedFile) && docPreviewBl) ? getFilePreview() : (
                <>
                  <Controls.Typography
                    variant={"subtitle1"}
                    sx={{ fontWeight: "medium" }}
                  >
                    {Constant.DOC_PREVIEW_LBL}
                    </Controls.Typography>
                  <DescriptionOutlinedIcon sx={{ height: 50, width: 50 }} />
                </>
              )
            ) : (
                  <Controls.Typography
                    variant={"subtitle1"}
                    sx={{ fontWeight: "medium" }}
                  >
                    {Constant.LBL_GETTING_PREVIEW}
                    <LinearProgress />
                  </Controls.Typography>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SectionGroupComponentDialogBoxUploadDoc);

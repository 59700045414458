import React, { useState, useEffect, createRef } from 'react'
import SectionGroup from './SectionGroupComponent';
import { Card, CardHeader, CardContent, Button, CircularProgress, Box, Typography, accordionActionsClasses, Stack, Link as MuiLink } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from "react-redux";
import {
  getSectionDetails, saveSection, getCreateCaseSection, createCase, updateDisableStatus,
  updateSelectedSectionName, updateCaseAmendmentBlStatus,
  updateSectionGroupLoaderStatus, updateActionButtonLoaderStatus, updateCaseEditableMode,
  saveRepUserUnConfirmedProducts, sendSectionReleaseInfo, resetSaveSectionDetailsFailureCount, updateSectionCompletionStatus,
  resetCreateCaseFailureStatus, isCreateCaseBl
} from '../../actions/CaseDetailsAction';
import { connect } from 'react-redux';
import {
  getBookingSheet, getSection, getSectionsNavigation, getActionList, getCaseView, caseAmendmentBl, getSectionGroupLoader,
  getActionButtonLoaderStatus, getAllSelections, getIfcaseInEditMode, getSaveFailureCount, getCreateCaseFailure, getDocumentSectionDetails
} from '../../reducers/CaseDetailsReducer';
import { dashboardFilterFields } from '../../reducers/DashboardReducer';
import { getActiveWorkQueueInfo } from '../../reducers/ReportsReducer';
import { Form } from './useForm'
import { DRAWER_DARK, DRAWER_REGULAR } from '../../res/values/Colors';
import Controls from '../controls/Controls';
import SectionHeader from '../common/SectionHeaderComponent';
import SectionAlertAction from '../../actions/SectionAlertAction';
import { setReturnToWorkQueue } from '../../actions/ReportsAction';
import CaseDialogBox from '../case/CaseDialogBox';
import * as R from 'ramda';
import { updateControlValue } from '../../actions/CaseDetailsAction'

import {
  ACTION_BUTTON_NEXT_ID,
  ACTION_BUTTON_SAVE_ID,
  ACTION_BUTTON_SAVE_AND_NEXT_ID,
  ACTION_BUTTON_BACK_ID,
  ACTION_BUTTON_CREATE_CASE_ID,
  CREATE_CASE_SECTION_ID,
  CANCEL_CASE_SECTION_ID,
  LBL_CONFIRM_ALL_PRODUCTS,
  INFO_ALL_PRODUCT_CONFIRMED,
  LBL_PATIENT_INFORMATION,
  ERR_UNSAVED_CHANGES_ON_BROWSER_EVENT,
  ERR_FAILED_TO_SAVE_CASE_RETRY,
  ERR_FAILED_TO_SAVE_CASE_CANCEL,
  EHR_INTEGRATION_ALERT,
  ERR_CREATE_CASE_FAILURE,
  ERR_INVALID_DATE,
  DOC_MANAGEMENT_PAGE_URL,
  RETURN_TO_WORK_QUEUE_LBL
} from '../../constants/GlobalConstants';
import { checkSectionValidation } from '../../utils/ruleEngine/ValidateSection'
import ExecuteRuleManagement from '../../utils/ruleEngine/AdditionPropertyRules/RuleManagement/ExecuteRuleManagement';
import { withRouter, Link } from "react-router-dom";
import Loader from '../common/Loader';
import LocalStorageProvider from '../../providers/LocalStorageProvider'
import { store } from '../../store';
import DocumentRedesignMockJSON from './../../Json/DocumentRedesignMockJSON.json'
import caseDetailsService from '../../services/CaseDetailsService';
import DocumentManagement from '../case/DocumentManagement'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import * as Colors from '../../res/values/Colors'


const cardStyle = { width: '100%', overflow: 'visible' }


const classes = {
  formActions: {
    float: 'right',
    margin: '16px'
  },
  actionButtons: {
    marginTop: '8px',
    cursor: 'pointer',
    marginBottom: '8px',
    display: 'inline-block',
    color: DRAWER_DARK
  },
  actionButtonsContainer: {
    marginTop: '8px',
    marginBottom: '8px',
    display: 'inline-block',
    color: DRAWER_DARK
  },
  actionButtonsDisable: {
    marginTop: '8px',
    marginBottom: '8px',
    cursor: '',
    display: 'inline-block',
    opacity: .5,
    color: DRAWER_REGULAR
  },

  cancelActionButton: {
    marginTop: '8px',
    cursor: 'pointer',
    marginBottom: '8px',
    marginRight: '1.8rem',
    display: 'inline-block',
    color: DRAWER_DARK
  },
  sectionLoader: {
    marginLeft: "-100px",
    marginTop: "80px",
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  returnWorkQueueBtn: {
    color: `${Colors.RETURN_WORK_QUEUE_LINK_COLOR} !important`
  }
}

class CaseSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSaveButtonDisable: true,
      isSectionCompleted: false,
      sectionDetails: null,
      isCreateCaseButtonDisable: false,
      isSectionEdited: false,
      sectionLockBl: false,
      sectionLockedByName: null,
      isShowSectionDataRefreshIcon: false,
      docSectionDetails: null
    };


    if (this.state.sectionDetails == null) {
      if (props.sectionId == CREATE_CASE_SECTION_ID && !this.props.isDashboard) {
        this.props.getCreateCaseSection(props.sectionId);
      }
    }
    else {
      if (this.state.sectionDetails.sectionId != props.sectionId) {
        if (props.sectionId == CREATE_CASE_SECTION_ID && !this.state.isDashboard) {
          this.props.getCreateCaseSection(props.sectionId);
        }
        else {
          // this.props.getSectionDetails(props.sectionId);
        }
      }
    }

  }


  static getDerivedStateFromProps(props, state) {
    if (props && props.sectionDetails && props.sectionDetails.sectionId) {
      //execute rule on section load for showing section level alert 
      if(!props?.sectionDetails?.additionalProperties){
        store.dispatch(SectionAlertAction.clearSectionAlert());
      }else{
        ExecuteRuleManagement.initializeEngine(props.sectionDetails)
      }

      //show warning  banner on top of each section based on below env parameters
      const isNeedToShowBSheetBanner = process.env?.REACT_APP_Banner_BSheet
      const bannerMessage = process.env?.REACT_APP_Banner_BSheet_Message

      if (eval(isNeedToShowBSheetBanner) && bannerMessage != null && bannerMessage.trim().length > 0) {
        store.dispatch(SectionAlertAction.showSectionAlert("BannerBSheet", "warning", bannerMessage, 0))
      }
      //return updated object for section lock/unlock
      return {
        sectionLockBl: props.sectionDetails.sectionLockBl,
        sectionLockedByName: props.sectionDetails.sectionLockedByName,
        isShowSectionDataRefreshIcon: props.sectionDetails.isShowSectionDataRefreshIcon ? props.sectionDetails.isShowSectionDataRefreshIcon : false,
        sectionDetails: props.sectionDetails,
        docSectionDetails: props.docSectionDetails
      };
    }
    return null;

  }


  getSelectedSectionId(actionId) {
    var sectionNavigations = this.props.SectionsNavigation;
    var nextSelectionId = null;
    for (var item = 0; item < sectionNavigations.length; item++) {
      if (sectionNavigations[item].id == this.props.sectionDetails.sectionId) {
        if (actionId != ACTION_BUTTON_BACK_ID) {
          nextSelectionId = sectionNavigations[item + 1].id;
        }
        else {
          nextSelectionId = sectionNavigations[item - 1].id;
        }
      }
    }
    return nextSelectionId;
  }

  onSubmitClick(actionId, isLocked) {
    var currentIndex = this.props.SectionsNavigation.indexOf(this.props.SectionsNavigation.filter((item) => item.id == (this.props.sectionDetails.sectionId))[0]);
    this.props.updateActionButtonLoaderStatus(true);
    var caseId = this.props.match.params.caseId;
    try {
      if (actionId == ACTION_BUTTON_SAVE_AND_NEXT_ID) {
        this.props.saveSection(this.props.sectionDetails.sectionId, actionId)
          .then(response => {
            var tempUrl = this.props.SectionsNavigation[currentIndex + 1].url
            this.props.handleCaseNavigation(this.props.sectionDetails.sectionId + 1);
            this.props.updateSelectedSectionName(tempUrl);
            const pageUrl = '/case/' + caseId + tempUrl;
            this.props.history.push(pageUrl);
          });
      }

      else if (actionId == ACTION_BUTTON_SAVE_ID) {
        window.removeEventListener("beforeunload", this.onSectionUnload);
        this.props.saveSection(this.props.sectionDetails.sectionId, actionId);
        this.setState({ isSectionEdited: false });

      }
      else if (actionId == ACTION_BUTTON_CREATE_CASE_ID) {
        if (!isLocked) {
          if (!this.state.isCreateCaseButtonDisable) {

            this.props.createCase();
            this.props.handleCaseNavigation(1, actionId);
          }
          this.setState({
            isCreateCaseButtonDisable: true
          })

        }
      }
      else if (actionId == ACTION_BUTTON_NEXT_ID) {
        var tempUrl = this.props.SectionsNavigation[currentIndex + 1].url
        this.props.updateSelectedSectionName(tempUrl);
        this.props.handleCaseNavigation(this.props.sectionDetails.sectionId + 1);
        const pageUrl = '/case/' + caseId + tempUrl;
        this.props.history.push(pageUrl);
      }
      else if (actionId == ACTION_BUTTON_BACK_ID) {
        var tempUrl = this.props.SectionsNavigation[currentIndex - 1].url
        this.props.updateSelectedSectionName(tempUrl);
        this.props.handleCaseNavigation(this.props.sectionDetails.sectionId - 1);
        const pageUrl = '/case/' + caseId + tempUrl;
        this.props.history.push(pageUrl);
      }
    } catch (err) {
      console.log("failed save from booking sheet")
    }
  }

  /**
    * Used to page/section validation  
    * @param {any} triggeredValue Keep control details or current section details
    * @param {any} status IF false/null then run validation engine else not
    * @param {any} isSectionEdited Show page is edited or not
    */
  validateSectionControls(triggeredValue = null, status = false, isSectionEdited = false) {
    if (this.props.sectionId == CREATE_CASE_SECTION_ID || isSectionEdited) {
      var response = checkSectionValidation(this.props.sectionDetails, status);
      var actionsList = [];
      if (response) {
        var uniqueEnableItems = [];
        for (let i = 0; i < response.mappingIds.length; i++) {
          if (!uniqueEnableItems[response.mappingIds[i]]) {
            uniqueEnableItems[response.mappingIds[i]] = 1;
            var item = {
              Id: response.mappingIds[i],
              status: false
            }
            actionsList.push(item);
          }
        }

        var uniqueDisableItems = [];
        for (let i = 0; i < response.selectedButtonsDisable.length; i++) {
          if (!uniqueDisableItems[response.selectedButtonsDisable[i]]) {
            uniqueDisableItems[response.selectedButtonsDisable[i]] = 1;
            var item = {
              Id: response.selectedButtonsDisable[i],
              status: true
            }
            actionsList.push(item);
          }
        }
      }
      this.props.updateSectionStatus(this.props.sectionDetails.sectionId, response.isSectionCompleted);
      this.props.updateDisableStatus(actionsList);
    }

  }

  // the method that will be used for both add and remove event
  onSectionUnload = e => {
    if (this.props.isSectionEditable) {
      e.preventDefault();
      e.returnValue = ERR_UNSAVED_CHANGES_ON_BROWSER_EVENT;
    }
  }

  async componentDidUpdate(prevProps) {

    // Current section data
    const newSectionData = this.props.sectionDetails;

    // Dispatch an action based on changes in Redux state
    if (!prevProps.sectionDetails && this.props.sectionDetails &&
      newSectionData.showSectionStatusBl && newSectionData.updatePageStatusOnLoad) {

      // CheckSectionValidation is validating if the section is complete or not
      const validationResponse = checkSectionValidation(newSectionData);

      //Update the status to UI and Backend if there's any diff.
      if (validationResponse.isSectionCompleted !== newSectionData.sectionCompleteBl) {
        this.props.updateSectionCompletionStatus({ sectionId: this.props.sectionDetails.sectionId, isSectionComplete: validationResponse.isSectionCompleted });
        
        const reponseStatusCode = await caseDetailsService.updateSectionStatusByCaseIdSectionId(validationResponse.isSectionCompleted, this.props.match.params.caseId, this.props.sectionDetails.sectionId);
        if (reponseStatusCode == '204') {
          this.props.updateSectionCompletionStatus({ sectionId: this.props.sectionDetails.sectionId, isSectionComplete: newSectionData.sectionCompleteBl });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onSectionUnload, { capture: true });
  }

  onEditClick() {

    this.props.updateCaseAmendmentBlStatus(!this.props.caseAmendmentBlStatus);
    this.setState({ isSectionEdited: true });
    this.props.updateCaseEditableMode(true);
    this.validateSectionControls(this.props.sectionDetails, false, true);

    //locking section on edit click event
    if (this.props.sectionDetails) {
      const userDetails = {
        TenantKey: LocalStorageProvider.getTenantKey()
      }
      const caseDetails = {
        CaseId: this.props.sectionDetails.bSheet_HeaderInformation ?
          this.props.sectionDetails.bSheet_HeaderInformation.caseId : this.props.match.params.caseId,
        SectionId: this.props.sectionDetails.sectionId,
      }
      this.props.sendSectionLockInfo(userDetails, caseDetails);
      window.addEventListener("beforeunload", this.onSectionUnload, { capture: true });
    }

  }

  onRefreshClick() {

    window.location.reload(); //TODO: Need to handle it by redux-store data
  }

  onVendorConfirmAllProductBtnClick() {
    const caseId = this.props.sectionDetails.bSheet_HeaderInformation ?
      this.props.sectionDetails.bSheet_HeaderInformation.caseId : this.props.match.params.caseId
    const caseDataObj = {
      CaseId: caseId
    }
    store.dispatch(saveRepUserUnConfirmedProducts(caseDataObj));
    store.dispatch(sendSectionReleaseInfo());
  }


  renderSectionHeader() {
    if (this.props.sectionDetails) {
      return (
        <SectionHeader
          sectionDetails={this.props.sectionDetails}
          onEditClick={(e) => { this.onEditClick(e) }}
          onRefreshClick={(e) => { this.onRefreshClick(e) }}
          amendButtonStatus={this.props.caseAmendmentBlStatus}
          userActions={this.props.dashboardFilterData}
          caseStatusId={this.props.sectionDetails.caseStatusId}
          sectionLockBl={this.state.sectionLockBl}
          sectionLockedByName={this.state.sectionLockedByName}
          isShowSectionDataRefreshIcon={this.state.isShowSectionDataRefreshIcon}
        />

      );
    }
  }

  renderGroups() {
    let sectionDetails = this.state.sectionDetails;

    //Document section has diffrent section structure
    if (this.props.sectionUrl == DOC_MANAGEMENT_PAGE_URL && this.state.docSectionDetails) {
      sectionDetails = this.state.docSectionDetails;
      return <DocumentManagement
        sectionDetails={sectionDetails}
        disabled={!this.state.isSectionEdited}
      />
    } else {
      if (sectionDetails) {
        let isSectionEdited = this.state.isSectionEdited;
        return sectionDetails.sectionGroup.map(group => {
          return (
            group.isVisible && <SectionGroup
              isLocked={this.isLocked}
              group={group}
              key={group.sectionGroupId}
              label={group.sectionGroupName}
              entitlementKey={group.description}
              disabled={sectionDetails.sectionId == CREATE_CASE_SECTION_ID ? false : this.state.isSectionEdited}
              validateSectionControls={(control) => this.validateSectionControls(control, false, isSectionEdited)} //validate section on control rendering 
              others={sectionDetails}
            />
          )
        })
      }
    }
  }

  isActionVisible(action) {
    var isVisible = false;

    const sortedSections = R.sort((a, b) => a.displayOrder < b.displayOrder, this.props.bookingSheetDetails)
    const bSheetFirstSectionId = sortedSections[0].sectionId
    const bSheetLastSectionId = sortedSections[sortedSections.length - 1].sectionId

    if ((this.props.caseAmendmentBlStatus && action.actionId != ACTION_BUTTON_NEXT_ID && action.actionId != ACTION_BUTTON_BACK_ID) ||
      (!this.props.caseAmendmentBlStatus && (action.actionId == ACTION_BUTTON_NEXT_ID || action.actionId == ACTION_BUTTON_BACK_ID))
      && (this.state.sectionDetails.sectionName != LBL_PATIENT_INFORMATION && action.actionId != ACTION_BUTTON_CREATE_CASE_ID)) {
      isVisible = true;
    }
    else if ((this.state.sectionDetails.sectionName == LBL_PATIENT_INFORMATION && this.props.caseAmendmentBlStatus && action.actionId != ACTION_BUTTON_NEXT_ID
      && action.actionId != ACTION_BUTTON_BACK_ID)
      || (this.state.sectionDetails.sectionName == LBL_PATIENT_INFORMATION && !this.props.caseAmendmentBlStatus && action.actionId == ACTION_BUTTON_NEXT_ID)) {
      isVisible = true;
    }
    else if (this.props.sectionId == CREATE_CASE_SECTION_ID && action.actionId == ACTION_BUTTON_CREATE_CASE_ID) {
      isVisible = true;
    }


    if ((this.state.sectionDetails.sectionId == bSheetLastSectionId) && !this.props.caseAmendmentBlStatus && (action.actionId == ACTION_BUTTON_NEXT_ID)) {
      isVisible = false;
    }

    if ((this.state.sectionDetails.sectionId == bSheetLastSectionId) && this.props.caseAmendmentBlStatus && (action.actionId == ACTION_BUTTON_SAVE_AND_NEXT_ID)) {
      isVisible = false;
    }

    if ((this.state.sectionDetails.sectionId == bSheetFirstSectionId && action.actionId == ACTION_BUTTON_BACK_ID)) {
      isVisible = false;
    }

    return isVisible;
  }

  renderVendorConfirmAllProductBtn(sectionDetails) {
    if (sectionDetails) {
      var isShowVendorConfirmAllProductBtn = false;
      var isShowVendorConfirmAllProductAlert = false;
      const currentControlHdnFieldId = "HFVendorConfirmAllProductsBl";
      sectionDetails.sectionGroup.forEach(x => x.fields.filter(element => element.mappingId == currentControlHdnFieldId)
        .forEach(element => {
          if (element.mappingId && element.value == 1) {
            isShowVendorConfirmAllProductBtn = true;
          }
          else if (element.mappingId && element.value == 0) {
            isShowVendorConfirmAllProductAlert = true;
          }
          else if (element.mappingId && element.value == null) {
            isShowVendorConfirmAllProductAlert = false;

          }
        }));

      //show/hide confirm product alert for rep user
      if (isShowVendorConfirmAllProductAlert) {
        store.dispatch(SectionAlertAction.showSectionAlert("VendorConfirmAllProductAlert", "info", INFO_ALL_PRODUCT_CONFIRMED))
      }
      // else {
      //   store.dispatch(SectionAlertAction.hideSectionAlert("VendorConfirmAllProductAlert"))
      // }

      //show/hide confirm all product buttons
      if (isShowVendorConfirmAllProductBtn) {
        return (
          <div style={classes.actionButtonsContainer} key={'VendorConfirmAllProductBtn '}>
            <Controls.Button
              label={LBL_CONFIRM_ALL_PRODUCTS}
              size='small'
              type="button"
              disabled={false}
              className={classes.actionButtons}
              onClick={(e) => { this.onVendorConfirmAllProductBtnClick(e) }}
            />
          </div>
        )
      } else {
        return null;
      }
    }
  }

  renderActionButtons() {
    if (this.state.sectionDetails && this.state.sectionDetails.sectionId != CANCEL_CASE_SECTION_ID && this.state.sectionDetails.sectionId != CREATE_CASE_SECTION_ID) {
      return (
        <div style={classes.formActions}>
          {this.renderVendorConfirmAllProductBtn(this.state.sectionDetails)}
          {this.props.actionList.map((action, index) => (
            this.isActionVisible(action) && action.actionId != 0 && !(!this.props.caseAmendmentBlStatus && (action.actionId == 2 || action.actionId == 3)) &&
            <div style={classes.actionButtonsContainer} key={action.actionId}>

              <Controls.Button
                label={action.label}
                size='small'
                type="button"
                disabled={this.props.actionButtonLoaderStatus || action.isLocked}
                loader={this.props.actionButtonLoaderStatus && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: '-30px' }}
                  />)}
                className={(this.props.caseAmendmentBlStatus || !action.isLocked) ? classes.actionButtons : classes.actionButtonsDisable}
                onClick={() => { this.onSubmitClick(action.actionId, action.isLocked) }}
              />

            </div>
          ))}
        </div>
      );
    }
    else if (this.state.sectionDetails && this.state.sectionDetails.sectionId != CANCEL_CASE_SECTION_ID) {
      return (
        <div style={classes.formActions}>

          {this.props.actionList.map((action, index) => (
            action.actionId == 0 &&
            <div style={classes.actionButtonsContainer}
              key={action.actionId}>

              <Controls.Button
                label={action.label}
                size='small'
                type="button"
                disabled={this.props.actionButtonLoaderStatus || !(!action.isLocked && (!this.state.isCreateCaseButtonDisable))}
                loader={this.props.actionButtonLoaderStatus && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: '-30px' }}
                  />)}
                className={!action.isLocked && (!this.state.isCreateCaseButtonDisable) ? classes.actionButtons : classes.actionButtonsDisable}
                onClick={() => { this.onSubmitClick(action.actionId, action.isLocked) }}
              />
            </div>


          ))}
        </div>
      );
    }
  }

  resetCreateCase() {
    this.props.isCreateCaseB(true);
    this.props.getCreateCaseSection(this.props.sectionDetails.sectionId);
    this.props.resetCreateCaseFailure(false);
    this.setState({
      isCreateCaseButtonDisable: false
    })
    store.dispatch(SectionAlertAction.clearSectionAlert())
    this.props.history.push('/case');
  }

  // This `resetControlValueByMappingId` is used to reset the date field getting blank and still validation restrict save functionaliy
  resetControlValueByMappingId = () => {
    const fieldControl = this.props.sectionDetails.sectionGroup.reduce((acc, initial) => {
      initial.fields.forEach(info => {
        if (info.value === ERR_INVALID_DATE) {
          acc.push(info.mappingId)
        }
      })
      return acc;
    }, []);
    if (fieldControl.length > 0) {
      fieldControl.forEach(item => this.props.updateControlValue(item, null))
    }
  }

  // This `confirmSaveFailure` function is used to handle save case failure dialog confirmation
  confirmSaveFailure() {
    if (this.props.saveFailureCount === 1) {
      this.onSubmitClick(ACTION_BUTTON_SAVE_ID, false);
      this.setState({ isSectionEdited: true });
    } else {
      this.props.resetCaseSaveFailureCount()
      // This is only for verify button on financial page
      if (this.props.sectionDetails.sectionId === 2) {
        this.resetControlValueByMappingId();
      }
    }
  }

  // This `cancelSaveFailure` function is used to handle save case failure dialog cancellation
  cancelSaveFailure() {
    if (this.props.saveFailureCount === 1) {
      this.props.resetCaseSaveFailureCount();
      this.setState({ isSectionEdited: true });
      // This is only for verify button on financial page
      if (this.props.sectionDetails.sectionId === 2) {
        this.resetControlValueByMappingId();
      }
    }
  }

  render() {
    const saveFailureDialog = (
      <CaseDialogBox
        open={this.props.saveFailureCount > 0}
        dialogDescription={this.props.saveFailureCount === 1 ? ERR_FAILED_TO_SAVE_CASE_RETRY : ERR_FAILED_TO_SAVE_CASE_CANCEL}
        onConfirm={() => this.confirmSaveFailure()}
        loading={this.props.actionButtonLoaderStatus}
        confirmDialog={this.props.saveFailureCount === 1 ? "RETRY" : "OK"}
        onCancel={this.props.saveFailureCount === 1 ? () => this.cancelSaveFailure() : null}
      />
    )

    //Create case failure
    const createCaseFailureDialog = (
      <CaseDialogBox
        open={this.props.createCaseFailure}
        dialogDescription={ERR_CREATE_CASE_FAILURE}
        onConfirm={() => this.resetCreateCase()}
        loading={this.props.actionButtonLoaderStatus}
      />
    )

    const workQueueHistory = sessionStorage.getItem("activeWorkQueueInfo")
    const workQueueHistoryInfo = workQueueHistory !== null ? JSON.parse(workQueueHistory) : null

    return (
      this.props.sectionGroupLoader ? <Box sx={classes.sectionLoader}>
        <Loader loading={this.props.sectionGroupLoader} />
        {this.props.caseDetails?.displayPatientMappingMessage && <Typography sx={{ marginTop: 5 }}>{EHR_INTEGRATION_ALERT}</Typography>}
      </Box> :
        <>
          <Controls.Alert />
          {saveFailureDialog}
          {createCaseFailureDialog}
          <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <div>{this.renderSectionHeader()}</div>
          <div style={{paddingRight: 20}}>
            {workQueueHistoryInfo !== null && workQueueHistoryInfo.isNavigateWorkQueue && (
              <Button 
                startIcon={<KeyboardBackspaceIcon />}
                sx={classes.returnWorkQueueBtn}
                onClick={() => {
                  this.props.updateReturnToWorQueueStatus(true)
                  sessionStorage.setItem("activeWorkQueueInfo", JSON.stringify({...workQueueHistoryInfo, isReturnToWorkQueue: true}))
                  this.props.history.push("/reports")
                }}
              >
                {RETURN_TO_WORK_QUEUE_LBL} 
              </Button>
            )}
          </div>
          </Stack>
          <Card style={cardStyle}
            elevation={0}>
            <CardContent>
              <Form>
                {this.renderGroups()}
                {/* Bottom Bar/ Action buttons */}
                {this.renderActionButtons()}
              </Form>
            </CardContent>
          </Card>
        </>

    );
  }
}

const mapStateToProps = state => ({
  bookingSheetDetails: getBookingSheet(state),
  sectionDetails: getSection(state),
  controlSelections: getAllSelections(state),
  SectionsNavigation: getSectionsNavigation(state),
  actionList: getActionList(state),
  isDashboard: getCaseView(state),
  caseAmendmentBlStatus: caseAmendmentBl(state),
  dashboardFilterData: dashboardFilterFields(state),
  sectionGroupLoader: getSectionGroupLoader(state),
  actionButtonLoaderStatus: getActionButtonLoaderStatus(state),
  isSectionEditable: getIfcaseInEditMode(state),
  saveFailureCount: getSaveFailureCount(state),
  createCaseFailure: getCreateCaseFailure(state),
  caseDetails: state.caseDetails,
  docSectionDetails: getDocumentSectionDetails(state),
  activeWorkQueueInfo: getActiveWorkQueueInfo(state)
});

const mapDispatchToProps = dispatch => ({
  updateSectionCompletionStatus: (data) =>
    dispatch(updateSectionCompletionStatus(data)),
  getSectionDetails: (Id) =>
    dispatch(getSectionDetails(Id)),
  saveSection: (sectionId, actionId) => dispatch(saveSection(sectionId, actionId)),
  updateSelectedSectionName: (sectionName) =>
    dispatch(updateSelectedSectionName(sectionName)),
  getCreateCaseSection: (Id) =>
    dispatch(getCreateCaseSection(Id)),
  createCase: () => dispatch(createCase()),
  updateDisableStatus: (mappingIds, status) =>
    dispatch(updateDisableStatus(mappingIds, status)),
  updateCaseAmendmentBlStatus: (status) =>
    dispatch(updateCaseAmendmentBlStatus(status)),
  updateSectionGroupLoaderStatus: (status) =>
    dispatch(updateSectionGroupLoaderStatus(status)),
  updateActionButtonLoaderStatus: (status) =>
    dispatch(updateActionButtonLoaderStatus(status)),
  updateCaseEditableMode: (mode) =>
    dispatch(updateCaseEditableMode(mode)),
  resetCaseSaveFailureCount: () => {
    dispatch(resetSaveSectionDetailsFailureCount())
  },
  resetCreateCaseFailure: (status) => {
    dispatch(resetCreateCaseFailureStatus(status))
  },
  isCreateCaseB: (status) => {
    dispatch(isCreateCaseBl(status))
  },
  updateControlValue: (mappingId, value, parentFieldId) => {
    dispatch(updateControlValue(mappingId, value, parentFieldId))
  },
  updateReturnToWorQueueStatus: (status) => {
    dispatch(setReturnToWorkQueue(status))
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CaseSection));

import React, { useEffect } from 'react'
import {
    Button,
    ButtonGroup,
    Badge,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CASE_NAVIGATION_TITLE } from '../../res/values/Colors'
import * as Dimens from '../../res/values/Dimens'
import Controls from '../controls/Controls'
import { getStatusCount, getActiveWorkQueueInfo } from '../../reducers/ReportsReducer'
import { useDispatch, useSelector } from 'react-redux'
import {
    UpdateFieldSorting,
    getReportsCases,
    getSearchFiltersReferenceData,
    setActiveStatus,
    updatePageNumberState,
    resetPaginationState,
    getAllReportsCaseCount,
    resetReportsCaseCount,
    addWorkQueueIndex,
    setIsNavigateWorkQueue,
    setReturnToWorkQueue,
    updateItemPerpageNumberState,
    setActiveWorkQueueInfo
} from '../../actions/ReportsAction'
import {
    LBL_WORK_QUEUES, LBL_INSURANCE_AUTHORIZATION, LBL_PRESURGICAL_TESTING_AT_HOSPITAL, LBL_PREADMISSION_TESTING_AT_HOSPITAL_2,
    LBL_PENDING_CONFIRMATION_NUMBER, LBL_CASE_AMENDMENT_TRACKER, NoInsuranceAuthorizationProvided,
    PreSurgicalTestingatHospital, PreAdmissionTestingAtHospital2, PendingConfirmationNumber, CaseAmendmentTracker, LBL_INPATIENT_ADMIT_TYPE, InpatientAdmitType, SCHEDULING_REPORT_FILTER_CONTROLS, INSURANCE_REPORT_FILTER_CONTROLS,
    LBL_SELECT_WORK_QUEUE, BADGE_MAX_CONTENT, LBL_VENDOR_CONFIRMATION_DUE, VendorConfirmationDue, INSURANCE_AUTHORIZATION, CASE_AMENDMENT_TRACKER,
    CMS_INPATIENT_ONLY, PENDING_CONFIRMATION_NUMBER, VENDOR_CONFIRMATION_DUE, PRESURGICAL_TESTING_AT_HOSPITAL, PREADMISSION_TESTING_AT_HOSPITAL
} from '../../constants/GlobalConstants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles(theme => ({
    headline: {
        textAlign: "left",
        color: CASE_NAVIGATION_TITLE,
        marginBottom: `${Dimens.HEADLINE_MARGIN_BOTTOM} !important`
    },
    optionBtn: {
        minWidth: `${Dimens.OPTION_BTN_WIDTH} !important`
    },
    popper: {
        zIndex: Dimens.POPPER_ZINDEX,
        minWidth: Dimens.POPPER_WIDTH
    },
    lableInBadge: {
        padding: Dimens.LBL_BADGE_PADDING
    }
}))

const ReportsLeftNav = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const CasesCount = useSelector(getStatusCount);
    const ActiveWorkQueue = useSelector(getActiveWorkQueueInfo);
    const reportsCasesCount = CasesCount.statusCount ? CasesCount.statusCount : null;
    const { workQueueList } = props;
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [reportOptions, setReportOptions] = React.useState([]);
    const workQueueHistory = sessionStorage.getItem("activeWorkQueueInfo")
    const workQueueHistoryInfo = workQueueHistory !== null ? JSON.parse(sessionStorage.getItem("activeWorkQueueInfo")) : null

    useEffect(() => {
        const workQueueCodeList = workQueueList.map(item => item.workQueueCode)
        dispatch(getAllReportsCaseCount(workQueueCodeList));
        if(workQueueHistoryInfo !== null && workQueueHistoryInfo.isReturnToWorkQueue && workQueueHistoryInfo.activeStatus.status === NoInsuranceAuthorizationProvided) {
            setActiveWorkQueueData(workQueueHistoryInfo)
        }
        return () => {
            dispatch(setActiveStatus(''))
            dispatch(resetReportsCaseCount());
        }
    }, []);

    useEffect(() => {
        // Initial state to render the nav options in drop down
        const options = workQueueList.map(item => {
            switch(item.workQueueCode) {
                case INSURANCE_AUTHORIZATION:
                    return {
                        label: item.workQueueName,
                        count: reportsCasesCount.NoInsuranceAuthorizationProvided,
                        status: NoInsuranceAuthorizationProvided,
                    }
                case PRESURGICAL_TESTING_AT_HOSPITAL:
                    return {
                        label: item.workQueueName,
                        count: reportsCasesCount.PreSurgicalTestingatHospital,
                        status: PreSurgicalTestingatHospital,
                    }
                case PREADMISSION_TESTING_AT_HOSPITAL:
                    return {
                        label: item.workQueueName,
                        count: reportsCasesCount.PreAdmissionTestingAtHospital2,
                        status: PreAdmissionTestingAtHospital2,
                    }
                case CMS_INPATIENT_ONLY:
                    return {
                        label: item.workQueueName,
                        count: reportsCasesCount.InpatientAdmitType,
                        status: InpatientAdmitType,
                    }
                case VENDOR_CONFIRMATION_DUE:
                    return {
                        label: item.workQueueName,
                        count: reportsCasesCount.VendorConfirmationDue,
                        status: VendorConfirmationDue,
                    }
                case PENDING_CONFIRMATION_NUMBER:
                    return {
                        label: item.workQueueName,
                        count: reportsCasesCount.PendingConfirmationNumber,
                        status: PendingConfirmationNumber,
                    }
                case CASE_AMENDMENT_TRACKER:
                    return {
                        label: item.workQueueName,
                        count: reportsCasesCount.CaseAmendmentTracker,
                        status: CaseAmendmentTracker,
                    }
            }
        })

        setReportOptions([...options]);
    }, [
        reportsCasesCount.NoInsuranceAuthorizationProvided,
        reportsCasesCount.PreSurgicalTestingatHospital,
        reportsCasesCount.PreAdmissionTestingAtHospital2,
        reportsCasesCount.InpatientAdmitType,
        reportsCasesCount.PendingConfirmationNumber,
        reportsCasesCount.CaseAmendmentTracker,
        reportsCasesCount.VendorConfirmationDue,
    ])

    const handleCaseStatusNavigation = (status) => {
        dispatch(resetPaginationState())
        dispatch(UpdateFieldSorting([]));
        dispatch(updatePageNumberState(0))
        dispatch(setActiveStatus(status))
        dispatch(getReportsCases(status))
        if (status === PendingConfirmationNumber) dispatch(getSearchFiltersReferenceData(SCHEDULING_REPORT_FILTER_CONTROLS));
        if (status === NoInsuranceAuthorizationProvided) dispatch(getSearchFiltersReferenceData(INSURANCE_REPORT_FILTER_CONTROLS));
    }

    const handleMenuItemClick = (option, index) => {
        const addWorkQueuePayload = {
            workQueueIndex: index,
            workQueueActiveStatus: option
        }
        setSelectedIndex(index)
        handleCaseStatusNavigation(option.status)
        setOpen(false);
        dispatch(addWorkQueueIndex(addWorkQueuePayload))
    };

    // Set work queue data if navigated through "Return to Work Queue" link
    const setActiveWorkQueueData = (workQueueInfo) => {
        const payload = {
            ...workQueueInfo,
            isNavigateWorkQueue: false,
            isReturnToWorkQueue: false
        }
        dispatch(updatePageNumberState(workQueueInfo.pageNumber))
        dispatch(updateItemPerpageNumberState(workQueueInfo.itemPerPage))
        setSelectedIndex(workQueueInfo.selectedWorkQueueIndex)
        dispatch(setActiveStatus(workQueueInfo.activeStatus.status))
        dispatch(getReportsCases(workQueueInfo.activeStatus.status))
        if (workQueueInfo.activeStatus.status === NoInsuranceAuthorizationProvided) dispatch(getSearchFiltersReferenceData(INSURANCE_REPORT_FILTER_CONTROLS));
        sessionStorage.setItem("activeWorkQueueInfo", JSON.stringify(payload))
        dispatch(setActiveWorkQueueInfo(payload))
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Controls.Typography
                variant='h6'
                className={classes.headline}>{LBL_WORK_QUEUES}
            </Controls.Typography>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
            >
                {selectedIndex === null ? (
                    <Button className={classes.optionBtn}>
                        <Controls.Typography className={classes.lableInBadge}>{LBL_SELECT_WORK_QUEUE}</Controls.Typography>
                    </Button>
                ) : (
                    <Button className={classes.optionBtn} onClick={() => handleCaseStatusNavigation(reportOptions[selectedIndex].status)}>
                        <Badge badgeContent={reportOptions[selectedIndex].count} color="secondary" max={BADGE_MAX_CONTENT} showZero>
                            <Controls.Typography className={classes.lableInBadge}>{reportOptions[selectedIndex].label}</Controls.Typography>
                        </Badge>
                    </Button>
                )}
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select work queue"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                className={classes.popper}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {reportOptions.map((option, index) =>
                                        (
                                            <MenuItem
                                                key={option.label}
                                                selected={index === selectedIndex}
                                                onClick={() => handleMenuItemClick(option, index)}
                                            >
                                                <Badge badgeContent={option.count} color="primary" max={BADGE_MAX_CONTENT} showZero>
                                                    <Controls.Typography className={classes.lableInBadge}>{option.label}</Controls.Typography>
                                                </Badge>
                                            </MenuItem>
                                        ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default ReportsLeftNav

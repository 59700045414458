import { DEFAULT_PERMISSION, PERMISSION_TYPE_ID } from '../../../constants/GlobalConstants';
import { store } from '../../../store';
import { getFieldByMappingId } from '../../GetFieldByMappingId';
import XControlUtils from '../../X-ControlUtils';

export const execute = (control) => {
  const tableRowIndex = control.mappingId.replace(/^\D+/g, "");
  const rule = control.additionalProperties.DisableTargetFieldIfDependentFieldDoesNotHaveAnyValue;
  let fieldId = rule.DependentFieldId;
  var dependentFieldId = fieldId.replaceAll("[n]", tableRowIndex);
  var permissionTypeIdValue = rule.IfTrueSetPermissionTypeId;
  var defaultPermissionTypeValue = null;

  if (rule.IfFalseSetPermissionTypeId && rule.IfFalseSetPermissionTypeId == DEFAULT_PERMISSION) {
    defaultPermissionTypeValue = control.defaultPermissionTypeId;
  }
  if (dependentFieldId.includes('.')) {
    var path = []
    path = dependentFieldId.split('.');
    var getFieldValue = getFieldByMappingId(path[1], path[0]);
  }
  for (var targetField of rule.TargetFieldId) {
    var fieldControlId = targetField.replaceAll("[n]", tableRowIndex);
    if (getFieldValue?.value == null)  {
      store.dispatch(XControlUtils.modifyControl(fieldControlId, PERMISSION_TYPE_ID, permissionTypeIdValue, parseInt(tableRowIndex)));
    } else {
      store.dispatch(XControlUtils.modifyControl(fieldControlId, PERMISSION_TYPE_ID, defaultPermissionTypeValue, parseInt(tableRowIndex)));
    }
  }
}
const DisableTargetFieldIfDependentFieldDoesNotHaveAnyValue = {
  execute
}
export default DisableTargetFieldIfDependentFieldDoesNotHaveAnyValue